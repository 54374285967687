import { createRouter, createWebHistory } from 'vue-router';
import store from "../store"

import TheMain from '../views/TheMain.vue';
import QuranQari from '../views/QuranQari.vue';
import Books from '../views/Books.vue';
import TheAbout from '../views/About.vue';
import TheContact from '../views/Contact.vue';
import Duruus from '../views/Duruus.vue';
import Audios from '../views/Audios.vue';
import Khutba from '../views/Khutba.vue';
import Mihadhara from '../views/Mihadhara.vue';
import Ruduud from '../views/Ruduud.vue';
import Login from '../views/Login.vue';
// import Recover from '../views/Recover.vue';
import Register from '../views/Register.vue';
import Dashboard from '../views/Dashboard.vue';
import Mashairi from '../views/Mashairi.vue';
import ShairiContent from '../views/ShairiContent.vue';
import Nasaha from '../views/Nasaha.vue';
import NotFound from '../views/NotFound.vue';




const router = createRouter({
	history: createWebHistory(),
	// base: process.env.BASE_URL,
	publicPath: '/Attamayyuz/',
	routes: [
		{ path: '/', component: TheMain },
		{ path: '/quran', component: QuranQari },
		{ path: '/books', component: Books },
		{ path: '/about', component: TheAbout },
		{ path: '/contact', component: TheContact },
		{ path: '/duruus', component: Duruus },
		{ path: '/audios/:audioId', component: Audios },
		{ path: '/khutba', component: Khutba },
		{ path: '/mihadhara', component: Mihadhara },
		{ path: '/ruduud', component: Ruduud },
		{ path: '/login', component: Login },
		// { path: '/recover', component: Recover },
		{ path: '/register', component: Register },
		{ path: '/dashboard', component: Dashboard },
		{ path: '/mashairi', component: Mashairi },
		{ path: '/shairi/:id', component: ShairiContent },
		{ path: '/nasaha/:id', component: Nasaha },
		{ path: '/:NotFound*', component: NotFound }
	],
});
router.beforeEach((to,_,next)=>{
	store.commit("setListen",to.path);
	next();
})
export default router;