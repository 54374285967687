import { createApp, h } from 'vue';
import App from './App.vue';

import { WaveUI, VueSmoothScroll, VueLoaders, QuillEditor } from './plugins';
import {
    NavBar,
    Card,
    Radio,
    Sidepanel,
    Footer,
    BaseDrawer,
    sidemenu,
    toolbar,
} from './components';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';
import router from './router';
import store from './store';
import './axios';

const app = createApp({
    setup() {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
        const recaptcha = async() => {
            // (optional) Wait until recaptcha has been loaded.
            await recaptchaLoaded();

            // Execute reCAPTCHA with action "login".
            const token = await executeRecaptcha('login');
            console.log(token);
            // Do stuff with the received token.
        };
        return {
            recaptcha,
        };
    },
    template: '<button @click="recaptcha">Execute recaptcha</button>',
    render: () => h(App),
});

new WaveUI(app, {
    breakpoints: {
        xs: 600,
        sm: 991,
        md: 1200,
        lg: 1700,
    },
});

app.component('nav-bar', NavBar);
app.component('site-footer', Footer);
app.component('card-content', Card);
app.component('radio-content', Radio);
app.component('sidepanel-content', Sidepanel);
app.component('base-drawer', BaseDrawer);
app.component('sidemenu', sidemenu);
app.component('toolbar', toolbar);
app.component('QuillEditor', QuillEditor);
app.config.productionTip = false;
app.use(router);
app.use(VueLoaders);
app.use(store);
app.use(VueReCaptcha, { siteKey: '6Lcvr1EcAAAAADG0Bfs8n2R7nMl8i9YqiSwt8t7N' });
app.use(VueSmoothScroll, {
    duration: 1000,
    updateHistory: false,
    offset: -380,
});

app.mount('#app');