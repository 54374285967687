<template>
	<section class="container">
		<div>
			<w-flex class="options row">
				<w-select
					:items="shekhList"
					:placeholder="isShekhLoading"
					inner-icon-left="mdi mdi-account"
					v-model="selectedShekh"
					outline
					bg-color="blue-light5"
					class="sm6 mt-3"
				/>
				<w-select
					:items="duruusType"
					:placeholder="isDuruusLoading"
					inner-icon-left="fa fa-book"
					v-model="selectedDuruus"
					outline
					bg-color="blue-light5"
					class="sm6 mt-3"
				/>
			</w-flex>
		</div>
		<div class="title-container row" v-if="!isLoading">
			<div
				class="titleBlock col-lg-12 col-md-12"
				v-for="title in titleList"
				:key="title.title_id"
			>
				<card-content>
					<div class="" @click="viewAudio(title)">
						{{ title.title_name }}
					</div>
				</card-content>
			</div>
		</div>
		<vue-loaders
			class="siteLoader"
			name="line-scale"
			color="#19bc9d"
			scale=".9"
			v-if="isLoading"
		></vue-loaders>
		<div class="loadMessage text-danger">{{ loadMessage }}</div>
	</section>
</template>
<script>
import axios from 'axios';
import cookie from 'vue-cookies';
export default {
	data() {
		return {
			isLoading: false,
			selectedShekh: '',
			isShekhLoading: 'Loading ...',
			isDuruusLoading: 'Loading ...',
			selectedDuruus: '',
			loadMessage: '',
			titleList: [],
			shekhList: [],
			duruusType: [],
		};
	},
	methods: {
		viewTitles() {
			this.loadMessage = '';
			this.titleList = [];

			if (this.selectedShekh && this.selectedDuruus) {
				cookie.set('confs', this.selectedShekh);
				cookie.set('confd', this.selectedDuruus);
				this.isShekhLoading = this.selectedShekh.shekh_name;
				this.isDuruusLoading = this.selectedDuruus.type_name;
				this.isLoading = true;
				// let key = 'dur_key';
				// let dataFound = localStorage.getItem(key);
				// let date = null;
				// if(dataFound!=null){
				// 	var res = JSON.parse(dataFound);
				// 	this.titleList = res.data;
				// 	date = res.max_update;
				// }
				// else{
				// 	this.isLoading = true;
				// }

				var opts = JSON.stringify({
					shekhId: this.selectedShekh.shekh_id,
					typeId: this.selectedDuruus.type_id,
					max_date: null,
				});

				axios
					.post('shekhDuruusTitle', opts)
					.then((response) => {
						this.isLoading = false;
						let result = response.data;
						if (result.status == 201) {
							this.titleList = result.data;
							// localStorage.removeItem(key)
							// localStorage.setItem(key,JSON.stringify(result));
						} else if (result.status == 1000) {
							// alert("Data Cached")
						} else if (result.status == 200) {
							this.loadMessage =
								response.data.status == 200
									? 'No Data Found'
									: '';
							// localStorage.removeItem(key);
							this.titleList = [];
						}
					})
					.catch(() => {
						this.isLoading = false;
						// if(dataFound==null){
						// 	this.titleList = []
						this.loadMessage =
							'Fail To Get Duruus, Please Try Again';
						// }
					});
			} else {
				this.isLoading = false;
			}
		},
		viewAudio(t) {
			cookie.set('conft', t.title_name);
			this.$router.push('/audios/' + t.title_id);
		},
		shekhDuruus() {
			axios
				.get('shekhDuruus')
				.then((data) => {
					let result = data.data;
					if (result.status == 200) {
						cookie.set('shekhList', { result: result.shekh });
						cookie.set('duruusType', { result: result.duruus });
						this.shekhList = result.shekh.map((x) => {
							return { label: x.shekh_name, value: x };
						});
						this.duruusType = result.duruus.map((x) => {
							return { label: x.type_name, value: x };
						});
					}
				})
				.catch((error) => {
					console.error(error.message);
					if (!cookie.isKey('shekhList')) {
						this.isShekhLoading = 'FAIL TO GET SHEKH LIST';
					}
					if (!cookie.isKey('duruusType')) {
						this.isDuruusLoading = 'FAIL TO GET DURUUS CATEGORY';
					}
				});
		},
		setCookie() {
			if (!cookie.isKey('confs') || !cookie.isKey('confd')) {
				cookie.set('confs', '');
				cookie.set('confd', '');
			}
			this.selectedShekh = cookie.get('confs') ?? '';
			this.selectedDuruus = cookie.get('confd') ?? '';

			this.viewTitles();
		},
	},
	watch: {
		selectedShekh() {
			this.viewTitles();
		},
		selectedDuruus() {
			this.viewTitles();
		},
		shekhList() {
			if (this.shekhList.length > 0) {
				this.isShekhLoading = 'Select Shekh';
				this.setCookie();
			} else {
				this.isShekhLoading = 'No Shekh Found';
			}
		},
		duruusType() {
			if (this.duruusType.length > 0) {
				this.isDuruusLoading = 'Select Duruus';
				this.setCookie();
			} else {
				this.isDuruusLoading = 'No Duruus Found';
			}
		},
	},
	created() {
		let shekh = cookie.get('shekhList')
			? cookie.get('shekhList')
			: { result: [] };

		let duruus = cookie.get('duruusType')
			? cookie.get('duruusType')
			: { result: [] };

		if (
			shekh.result &&
			shekh.result.length > 0 &&
			duruus.result &&
			duruus.result.length > 0
		) {
			this.shekhList = shekh.result.map((x) => {
				return { label: x.shekh_name, value: x };
			});
			this.duruusType = duruus.result.map((x) => {
				return { label: x.type_name, value: x };
			});
		}
		this.shekhDuruus();
	},
};
</script>
<style scoped>
.title-container {
	padding: 0px;
	margin: 0px;
	margin-top: 15px;
}
</style>
