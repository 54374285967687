<template>
	<section>
		<!-- <router-link to="/login"
			>
			<div class="bg-main white download">
				<w-icon class="mt-0 pr-3" size="20px">
					mdi mdi-android
				</w-icon>
				<span class="mt-5"> Android App</span>
				<w-icon class="float-right mt-1" size="20px">
					mdi mdi-download
				</w-icon>
			</div>
		</router-link> -->
		<!-- <router-link to="/login"
			>
			<div class="bg-main white download">
				<w-icon class="mt-0 pr-3" size="20px">
					mdi mdi-apple
				</w-icon>
				<span class="mt-5"> IOS App</span>
				<w-icon class="float-right mt-1" size="20px">
					mdi mdi-download
				</w-icon>
			</div>
		</router-link> -->
		<div class="bg-main white download mt-2">
			<w-icon class="mt-0 pr-3" size="20px">
				mdi mdi-radio
			</w-icon>
			<span class="mt-5"> Quran 24/7</span>
		</div>
		<div style="overflow:hidden;word-wrap:break-word;padding: 0px;">
			<!-- <audio controls="" autoplay="" name="media" style="width: 100%;border-radius: 0px;"><source src="http://live.mp3quran.net:8006/" type="audio/mpeg"></audio> -->
			<audio controls="" name="media" style="width: 100%;border-radius: 0px;"><source src="https://Qurango.net/radio/hani_arrifai" type="audio/mpeg"></audio>
		</div>
		<router-link to="/login"
			>
			<div>
				Uliza Swali 
				<w-icon class="float-right mt-1" size="20px">
					mdi mdi-chevron-right
				</w-icon>
			</div>
		</router-link>
		<router-link to="/mashairi"
			><div>
				Mashairi
				<w-icon class="float-right mt-1" size="20px">
					mdi mdi-chevron-right
				</w-icon>
			</div>
		</router-link>
		
	</section>
</template>
<script>
export default {
	data() {
		return {
			email: '',
			error: [],
			success: false,
			isLogin: false,
			isLogouting: false,
			isSubscribe: false,
			subscribeState: 0,
			verify: '',
			dialog: {
				show: false,
				fullscreen: false,
				transition: 'bounce',
				overlayOpacity: 0.3,
				overlayChoice: 'red',
				width: 500,
			},
		};
	},
};
</script>
<style scoped>
section {
	padding: 0px;
}
section div {
	text-align: left;
	padding: 5px 15px;
	margin: 0px;
	margin-top: 6px;
	box-shadow: 0 0px 1px gray;
	border-radius: 0px;
	cursor: pointer;
	border: 1px solid white;
	color: rgb(94, 91, 91);
	transition: 0.2s ease-in-out;
	font-weight: bold;
	font-size: 15px;
	border-left: 2px solid #19bc9d;
}

section div:hover {
	/* background: rgba(0, 0, 0, 0.07); */
	border-left: 2px solid #19bc9d;
	color: #19bc9d;
}
section div.download:hover {
	color: white
}
section a {
	text-decoration: none;
	color: #1d416dbe;
}
section .button {
	width: 100%;
	margin-top: 6px;
	padding: 5px;
	background: #19bc9d;
}
section .button:active {
	border: none;
}
.bg-default{
	background:#404d5e;
}
</style>
