<template>
	<section>
		<div class="wraper" style="min-height: 100vh">
			<div class="signup-form">
				<div
					class="alert alert-danger text-left p-1 m-1"
					v-for="(err, index) in error"
					:key="index"
				>
					{{ err }}
				</div>
				<!-- <router-link to="/" class="back"
					><w-button
						icon="mdi mdi-arrow-left"
						color="red"
						text
						lg
						class="ml3"
					></w-button>
					Site</router-link
				>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<router-link to="/login" class="back"
					><w-button
						icon="mdi mdi-login"
						color="red"
						text
						lg
						class="ml3"
					></w-button>
					Login</router-link
				> -->

				<form
					action="#"
					method="post"
					autocomplete="off"
					@submit.prevent="register()"
					class="bg-super"
				>
					<div v-if="registerState == 0">
						<!-- <h2>Sign Up</h2>
					<hr /> -->
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="fa fa-user"></i>
									</span>
								</div>
								<input
									type="text"
									class="form-control"
									name="username"
									placeholder="Jina"
									required="required"
									v-model="username"
								/>
							</div>
						</div>
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="fa fa-paper-plane"></i>
									</span>
								</div>
								<input
									type="email"
									class="form-control"
									name="email"
									placeholder="Barua pepe"
									required="required"
									v-model="email"
								/>
							</div>
						</div>
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="fa fa-lock"></i>
									</span>
								</div>
								<input
									type="password"
									class="form-control"
									name="password"
									placeholder="Neno siri"
									required="required"
									v-model="password"
								/>
							</div>
						</div>
						<div class="form-group">
							<div class="input-group">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<i class="fa fa-lock"></i>
										<i class="fa fa-check"></i>
									</span>
								</div>
								<input
									type="password"
									class="form-control"
									name="confirm_password"
									placeholder="Thibitisha neno siri"
									required="required"
									v-model="confirm"
								/>
							</div>
						</div>
						<!-- <div class="form-group">
							<label class="form-check-label"
								><input type="checkbox" required="required" /> I accept
								the
								<router-link to="/terms">Terms of Use</router-link>
								&amp;
								<router-link to="/policy"
									>Privacy Policy</router-link
								></label
							>
						</div> -->
						<div class="form-group">
							<w-button
								type="submit"
								class="btn btn-primary btn-block"
								:loading="isRegister"
							>
								JISAJILI
							</w-button>
						</div>
					</div>
					<p
						style="color: #19bc9d"
						class="text-center"
						v-if="registerState == 1"
					>
						Nambari za uthibitisho zimetumwa katika email yako<br />
						Tafadhali Ingiza Kuthibitisha Usajili Wako
					</p>
					<input
						type="text"
						name="verify"
						class="form-control"
						placeholder="Kodi za uthibitisho"
						required
						v-model="verify"
						v-if="registerState == 1"
					/>
					<div class="form-group mt5" v-if="registerState == 1">
						<w-button
							type="submit"
							class="btn btn-primary btn-block"
							:loading="isRegister"
						>
							THIBITISHA
						</w-button>
					</div>
				</form>
				<div class="form-group" v-if="registerState == 1">
					<w-button
						type="button"
						class="btn btn-block register"
						:loading="isResend"
						@click="register(true)"
					>
						Tuma Tena
					</w-button>
				</div>
			</div>

			<w-notification
				v-model="notification.show"
				:timeout="notification.timeout"
				success
				plain="plain"
				top
				shadow="shadow"
				transition="scale-fade"
				>{{ notification.msg }}</w-notification
			>
			<w-notification
				v-model="notification.showError"
				:timeout="notification.timeout"
				error
				plain="plain"
				top
				shadow="shadow"
				transition="scale-fade"
				>{{ notification.msg }}</w-notification
			>
		</div>
	</section>
</template>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			username: '',
			email: '',
			password: '',
			confirm: '',
			verify: '',
			statusCode: null,
			error: [],
			isRegister: false,
			isResend: false,
			registerState: 0,
			notification: {
				show: false,
				showError: false,
				timeout: 4000,
				msg: '',
				position: ['true', 'right'],
			},
		};
	},
	methods: {
		clearData() {
			this.username = '';
			this.email = '';
			this.password = '';
			this.confirm = '';
			this.$router.push('/login');
		},
		getObject() {
			return {
				name: this.username,
				email: this.email,
				password: this.password,
				password_confirmation: this.confirm,
				code: this.verify,
				address: '',
				phone: '',
				pcode: 'web',
				vercode: localStorage.getItem('tr_tmp'),
			};
		},

		register(resend = false) {
			this.error = [];

			let obj = this.getObject();
			if (resend) {
				this.isResend = true;
			} else {
				this.isRegister = true;
			}
			axios
				.post(
					this.registerState == 0 || resend ? 'reg' : 'verifier',
					obj,
					{
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
						},
					}
				)
				.then((response) => {
					if (!this.isResend) {
						this.isRegister = false;
						this.isResend = false;
						let code = response.status;
						if (code == 201 && this.registerState == 0) {
							this.registerState = 1;
						} else if (code == 201 && this.registerState == 1) {
							this.registerState = 0;
							this.notification.msg =
								'Registration Successfull, Login now';
							this.notification.show = true;
							this.clearData();
						} else if (code == 200) {
							let msg = '';
							if (response.data.message) {
								msg = 'Is Invalid';
								this.error = [
									response.data.errors[0] + ', ' + msg,
								];
							} else {
								this.error = response.data.errors;
							}
						} else if (code == 205 && this.registerState == 1) {
							this.error = ['Invalid Verification Code'];
						} else {
							this.error = [
								'Fail To Register, Please try again letter',
							];
						}
					} else {
						this.isResend = false;
					}
				})
				.catch(() => {
					this.isRegister = false;
					this.notification.msg =
						'Error Occured, Check Your Network !!!';
					this.notification.showError = true;
				});
		},
		rndStr(len) {
			let text = ' ';
			let chars = 'abcdefghijklmnopqrstuvwxyz';
			for (let i = 0; i < len; i++) {
				text += chars.charAt(Math.floor(Math.random() * chars.length));
			}

			return text;
		},
	},
	mounted() {
		let st = localStorage;
		if (st.getItem('tr_tmp') == null) {
			let message = this.rndStr(15);
			st.setItem('tr_tmp', message);
		}
	},
};
</script>
<style scoped>
.form-control {
	font-size: 15px;
}
.form-control,
.form-control:focus,
.input-group-text {
	border-color: #e1e1e1;
}
.form-control,
.btn {
	border-radius: 3px;
}
.signup-form {
	width: 95%;
	max-width: 400px;
	margin: 0 auto;
	padding: 30px 0;
	padding-top: 0%;
	margin-top: 30px;
}
.signup-form form {
	color: #999;
	border-radius: 3px;
	margin-bottom: 15px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
}
.signup-form h2 {
	color: #333;
	font-weight: bold;
	margin-top: 0;
}
.signup-form hr {
	margin: 0 -30px 20px;
}
.signup-form .form-group {
	margin-bottom: 20px;
}
.signup-form label {
	font-weight: normal;
	font-size: 15px;
}
.signup-form .form-control {
	min-height: 38px;
	box-shadow: none !important;
}
.signup-form .input-group-addon {
	max-width: 42px;
	text-align: center;
}
.signup-form .btn,
.signup-form .btn:active {
	font-size: 16px;
	font-weight: bold;
	background: #19aa8d !important;
	border: none;
	min-width: 140px;
}

.signup-form .btn:hover,
.signup-form .btn:focus {
	background: #179b81 !important;
}

.btn.register,
.btn.register:hover,
.btn.register:active,
.btn.register:focus {
	background: #f7f7f7 !important;
	color: #1d416d !important;
	box-shadow: 0px 1px 3px #19bc9d !important;
}
.signup-form a {
	color: #fff;
	text-decoration: underline;
}
.signup-form a:hover {
	text-decoration: none;
}
.signup-form form a {
	color: #19aa8d;
	text-decoration: none;
}
.signup-form form a:hover {
	text-decoration: underline;
}
.signup-form .fa {
	font-size: 18px;
	color: #19bc9d;
}
.signup-form .fa-check {
	color: #fff;
	left: 17px;
	top: 18px;
	font-size: 7px;
	position: absolute;
}
.input-group-prepend span i {
	width: 18px;
}
.input-group-text {
	background: white;
	color: #19bc9d;
}
a.back {
	text-decoration: none;
	color: brown;
}
</style>
