<template>
	<aside class=" white bg-white">
		<div class="nav-menu-wrap side-bar">
			<div class="header">
				<center>
					<img class='bg-gray' src="../../assets/logo.png" alt="" />
					<h5 class="txt-main">ATTAMAYYUZ</h5>
					<p class="text-secondary text-email">info@attamayyuz.or.tz</p>
				</center>
			</div>
			<div class="nav-menu mt5">
				<w-list
					:hover="false"
					color="white"
					:items="sections"
					nav
					@item-select="onItemClick"
				></w-list>
			</div>
		</div>
	</aside>
</template>
<script>
export default {
	data() {
		return {
			sections: [
				{
					label: 'Nyumbani',
					route: '/',
				},
				{
					label: 'Kuhusu Sisi',
					route: '/about',
				},
				{
					label: 'Wasiliana Nasi',
					route: '/contact',
				},
				{ label: 'Darsa', route: '/duruus' },
				{ label: 'Khutba', route: '/khutba' },
				{ label: 'Mihadhara', route: '/mihadhara' },
				{ label: 'Ruduud', route: '/ruduud' },
				{ label: 'Quran', route: '/quran' },
				{ label: 'Vitabu', route: '/books' },
			],
		};
	},
	methods: {
		onItemClick() {
			this.$emit('drawerOpen', false);
			// setTimeout(() => {
			// 	document.documentElement.scrollTop =
			// 		document.querySelector('.content-wrap').offsetTop - 42;
			// }, 200);
		},
	},
	mounted() {
	
	},
};
</script>
<style scoped>
*,*:hover,*:active{
	text-decoration: none !important;
	list-style-type: none;
	border: 0px solid;
}
aside {
	max-width: 250px;
	width: 100%;
	position: fixed;
	padding-top: 42px;
	/* height: 100vh; */
	overflow: auto;
	background: #19bc9d;
	top: 0px;
	bottom: 0px;
	left: 0px;
	/* display: none; */
}
.nav-menu-wrap {
	position: relative;
	/* min-width: 200px;
	max-width: 200px; */
	border-right: 0px solid #ddd;
}
div.nav-menu {
	min-height: 50vh;
}
div.nav-menu .w-list__item-label {
	padding: 6px;
	padding-left: 4;
	font-size: 1em;
	font-weight: normal;
	border-left: 2px solid transparent;
	text-decoration: none !important;
}
div.nav-menu .w-list__item-label--hoverable{
	text-decoration: none !important;
}
div.nav-menu .w-list__item-label:before {
	display: none;
}
div.nav-menu
	.w-list__item-label:not(.w-list__item-label--disabled):focus:before,
div.nav-menu
	.w-list__item-label:not(.w-list__item-label--disabled):hover:before {
	display: block;
}
div.nav-menu .w-list__item-label.router-link-active {
	border-left-color: red;
	border-width: 2px;
	font-weight: 700;
}
div.nav-menu .w-list__item-label.router-link-active:before {
	display: block;
	opacity: 0.15;
}
div.nav-menu .w-list__item-label:before {
	left: -2px;
}
div.nav-menu .w-list__item--parent > .w-list__item-label {
	text-transform: uppercase;
	font-weight: bold;
}
div.nav-menu .w-tag {
	padding: 2px 4px 1px;
}
.content-wrap .nav-menu-wrap {
	padding-top: 24px;
	margin-right: 32px;
}
.content-wrap .nav-menu {
	position: sticky;
	top: 60px;
}

@media screen and (max-width: 991px) {
	.nav-menu-wrap {
		top: 0px;
		bottom: 0px;
	}
}
*
{
	font-weight: bold;
	text-decoration: none;
}

side-bar {
	position: fixed;
	top: 0px;
	bottom: 0px;
	width: 230px;
	background-color: white;
	box-shadow: 0 0 5px black;
	overflow-y: auto;
}


/* HEADER PART */
.side-bar .header {
	padding-bottom: 20px;
	border-bottom: 6px solid #d05663;
	background-image: url('../../assets/app_bg.png');
}
.side-bar .header img {
	width: 70px;
	height: 70px;
	border-radius: 80px;
	/*background-color: #19bc9d;*/
	border: 3px solid #d05663;
	margin: 15px 20px;
	padding: 5px;
}
h5,
p {
	padding-left: 0px;
}
h5{
	font-size: 14px !important;
	font-weight: 100 !important;
}
p {
	font-size: 14px;
}


.bg-primary {
    background-color: #19bc9d !important;;
}

.bg-secondary {
    background-color: #d05663 !important;;
}

.text-primary {
    color: #19bc9d !important;;
}

.text-secondary {
    color: #d05663 !important;
}

.text-gray {
    color: dimgray !important;;
}
.text-email{
	font-size: 12px !important;
}
div.nav-menu a{
	text-decoration: none !important;
}
</style>
