<template>
	<section class="main">
		<!-- <img src="../assets/line.png" alt="" style="width:100%;"> -->
		<div class="wraper" id="wraper" style="min-height: 130vh">
			<w-card
				:no-border="true"
				shadow
				class="post-content pa0 ma1 mt6"
				style="
					min-height: 100vh;
					border-left: 0px solid #19bc9d;
					background: rgba(255, 253, 245, 0.2);
					padding: 0px;
				"
			>
				<vue-loaders
					class="siteLoader"
					name="line-scale"
					color="#19bc9d"
					scale=".9"
					v-if="isLoading"
				></vue-loaders>
				<div v-else-if="!isLoading && isError" class="load-error">
					<span class="text-danger">{{ isError }}</span
					><br /><button
						@click="loadData()"
						class="btn btn-info mt-4"
					>
						Retry
					</button>
				</div>
				<div
					v-else-if="
						!isLoading && currentMakala && currentMakala.length > 0
					"
					class="post-box"
					id="currentMakala"
				>
					<div class="post-header txt-main pa2 mb-5">
						{{ makalaTitle }}
					</div>
					<div
						v-for="(post, index) in currentMakala"
						:key="index"
						:class="contentClass(post.type_id)"
						style="background: rgba(255, 255, 255, 0.3)"
					>
						<a
							:href="post.desc"
							v-if="post.type_id == 7"
							target="_blank"
							>{{ post.desc }}</a
						>
						<span v-else>{{ post.desc }}</span>
					</div>
				</div>
				<div v-else class="text-danger">NO MAKALA FOUND !!!</div>
				<h6
					class="mt9 text-left white bg-main pa2"
					style="word-spacing: 3px; letter-spacing: 2px"
					v-if="recentMakala.length > 0"
				>
					MAKALA ZILIZOPITA
				</h6>
				<div>
					<a
						href="#currentMakala"
						v-smooth-scroll
						v-for="recent in recentMakala"
						:key="recent.id"
						@click="changeMakala(recent)"
					>
						<card-content class="primary ma0 mb2">
							{{ recent.title }}
						</card-content>
					</a>
				</div>

				<h6
					class="mt9 text-left white bg-main pa2"
					style="word-spacing: 3px; letter-spacing: 2px"
					v-if="darsa_list.length > 0"
				>
					VIPINDI VYA DARSA
				</h6>
				<w-card
					class="text-left darsa"
					style="background: rgba(255, 255, 255, 0.3)"
					v-if="darsa_list.length"
				>
					<Carousel :autoplay="30000" :wrap-around="true">
						<Slide v-for="slide in darsa_list" :key="slide">
							<div class="carousel__item">
								<p class="txt-main title">{{ slide.mada }}</p>
								<hr class="gray--bg" />
								<p>
									<span class="text-bold txt-main mt-4"
										>PAHALA </span
									>&nbsp; >>> {{ slide.place }}
								</p>
								<p>
									<span class="text-bold txt-main">SIKU </span
									>&nbsp; &nbsp; &nbsp; &nbsp; >>>
									{{ slide.siku }}
								</p>
								<!-- <p><span class="text-bold txt-main">TAREHE  </span>&nbsp; >>> {{ slide.date }}</p> -->
								<p>
									<span class="text-bold txt-main">MUDA </span
									>&nbsp; &nbsp; &nbsp; >>> {{ slide.muda }}
								</p>
							</div>
						</Slide>

						<template #addons="{ slidesCount }">
							<Navigation v-if="slidesCount > 1" />
							<!-- <Pagination /> -->
						</template>
					</Carousel>
				</w-card>
			</w-card>
			<w-card
				:no-border="true"
				shadow
				class="menu-tabs pa0 ma0 bg-trans"
				style="min-height: 100vh; background: rgba(255, 253, 245, 0.2)"
			>
				<div
					class="nasaha danger radius-4 mt-0"
					v-if="nasaha.length > 0"
				>
					<div class="block pa0 pl-0 pr-0">
						<p class="txt-main text-left text-bold pt-2 pl-1">
							NASAHA
						</p>
						<hr class="ma0 bg-main" />
						<p class="gray text-justify">
							<Carousel :autoplay="30000" :wrap-around="true">
								<Slide v-for="slide in nasaha" :key="slide">
									<router-link
										:to="'/nasaha/' + slide.id"
										class="content-link"
									>
										<div class="carousel__item">
											{{ slide.title }}
										</div>
									</router-link>
								</Slide>

								<template #addons="{ slidesCount }">
									<Navigation v-if="slidesCount > 1" />
									<Pagination style="display: none" />
								</template>
							</Carousel>
						</p>
					</div>
				</div>

				<!-- <img src="../assets/bg.jpg" alt="" style="width: 100%"> -->
				<div class="mt-2">
					<sidepanel-content></sidepanel-content>
				</div>
				<div class="mt6" v-for="radio in ourRadio" :key="radio.id">
					<iframe
						:src="radio.content"
						width="100%"
						height="180px"
						scrolling="no"
						frameborder="no"
						marginheight="0"
						marginwidth="0"
					></iframe>
				</div>
			</w-card>
		</div>
		<site-footer class="black--bg"></site-footer>
	</section>
</template>
<script>
import axios from 'axios';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
export default {
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	data() {
		return {
			ourRadio: [],
			nasaha: [],
			darsa_list: [],
			isLoading: false,
			isError: null,
			currentMakala: [],
			recentMakala: [],
			makalaTitle: '',
			date: new Date(),
			attributes: [
				{
					key: 'today',
					highlight: 'teal',
					dates: new Date(),
				},
			],
		};
	},
	methods: {
		contentClass(type) {
			type = Number(type);
			switch (type) {
				case 1:
					return 'sub';
				case 2:
					return 'desc';
				case 3:
					return 'ayah';
				case 4 || 8:
					return 'primary text-justify';
				case 5:
					return 'hadith';
				case 6:
					return 'picture';
				case 7:
					return 'link';
				default:
					return 'desc';
			}
		},
		changeMakala(content) {
			this.makalaTitle = content.title;
			this.currentMakala = content.content;
		},
		loadData() {
			// let date = {
			// 	nasahaDate: null,
			// 	makalaDate: null,
			// 	darsaDate: null,
			// };
			// if (localStorage.getItem('apt') != null) {
			// 	date = localStorage.getItem('apt');
			// }

			this.isError = null;

			this.isLoading = true;
			axios
				.post('makala')
				.then((data) => {
					let result = data.data;
					this.isLoading = false;
					this.isError = null;
					if (result.status == 201) {
						// localStorage.setItem(
						// 	'apt',
						// 	JSON.stringify(result.max_update)
						// );
						// if (result.makala != 1000)
						// 	localStorage.setItem(
						// 		'ch_mk',
						// 		JSON.stringify(result.makala)
						// 	);
						// if (result.darsa != 1000)
						// 	localStorage.setItem(
						// 		'ch_dr',
						// 		JSON.stringify(result.darsa)
						// 	);
						// if (result.nasaha != 1000)
						// 	localStorage.setItem(
						// 		'ch_ns',
						// 		JSON.stringify(result.nasaha)
						// 	);
						this.displayContent('new', result);
					} else if (result.status == 1000) {
						console.log('No Change');
					}
				})
				.catch(() => {
					// alert("Error : " + err);
					if (localStorage.getItem('ch_mk') == null) {
						this.isLoading = false;
						this.isError = 'FAIL TO GET DATA, PLEASE TRY AGAIN !!!';
					}
				});
		},
		displayContent(status, result) {
			// let makala = localStorage.getItem('ch_mk')
			// 	? JSON.parse(localStorage.getItem('ch_mk'))
			// 	: [];
			// let darsa = localStorage.getItem('ch_dr')
			// 	? JSON.parse(localStorage.getItem('ch_dr'))
			// 	: [];
			// let nasaha = localStorage.getItem('ch_ns')
			// 	? JSON.parse(localStorage.getItem('ch_ns'))
			// 	: [];

			this.makalaTitle = result.makala[0].title ?? '';
			this.currentMakala = result.makala[0].content;
			this.recentMakala = result.makala;
			this.ourRadio = [];
			this.nasaha = result.nasaha;
			this.darsa_list = result.darsa;
			if (status == 'exist') {
				this.loadData();
			}
		},
	},
	created() {
		// if (
		// 	localStorage.getItem('ch_mk') != null ||
		// 	localStorage.getItem('ch_ns') ||
		// 	localStorage.getItem('ch_dr')
		// ) {
		// 	this.displayContent('exist');
		// } else {
		this.loadData();
		// }
	},
};
</script>
<style>
.main {
	position: relative;
	word-wrap: break-word;
}
.main .wraper {
	position: relative;
	word-wrap: nowrap;
	transition: 1s;
	width: 100%;
}
.main .post-content {
	position: relative;
	height: inherit;
	margin-right: 322px;
	left: 5px;
}
.main .post-content .post-box {
	padding: 0px;
	min-height: 100vh;
	letter-spacing: 1px;
	/* box-shadow: 0 0 2px lightgray inset; */
}
.main .post-content .post-box:first-child {
	margin-top: 0px;
}
.main .post-content .post-box .post-header {
	/* color: #6c757d; */
	font-weight: bold;
	text-align: left;
}
.main .post-content .post-box p {
	width: 100%;
	text-align: justify;
}

.main .post-content .post-box * {
	margin: 3px;
	margin-top: 12px;
	letter-spacing: 1px;
}
.main .post-content .post-box .title,
.main .post-content .post-box .sub {
	color: goldenrod;
}
.main .post-content .post-box .desc {
	color: rgba(0, 0, 0, 0.8);
}
.main .post-content .post-box .ayah {
	color: red;
}
.main .post-content .post-box .ayah-translate,
main .post-content .post-box .hadith-translate {
	color: blue;
}
.main .post-content .post-box .hadith {
	color: green;
}
.main .post-content .post-box .ayah,
.main .post-content .post-box .hadith {
	text-align-last: right;
	font-family: quran;
	font-size: 30px;
}
.ayah,
.desc,
.ayah,
.ayah-translate,
.hadith {
	text-align: justify;
}
.sub {
	text-align: left;
	font-weight: bold;
	padding-top: 40px;
}
.menu-tabs {
	position: absolute;
	right: 5px;
	top: 0px;
	width: 300px;
}
.menu-tabs .tabs {
	height: 40px;
	/* cursor: pointer; */
}
.menu-tabs .tabs div {
	width: 100%;
	background: white;
	color: #19bc9d;
	border-radius: 1px;
	transition: 0.3s;
	text-align: left;
}
.menu-tabs .tabs div.active {
	background: #19bc9d;
	color: white;
}

@media (max-width: 750px) {
	.menu-tabs {
		position: relative;
		left: 8px;
		min-height: 200px;
		margin-top: 100px;
		width: 96%;
	}
	.main .post-content {
		margin-right: 16px;
		left: 6px;
	}
}

.siteLoader,
.load-error {
	padding-top: 200px;
}
a {
	text-decoration: none;
}
/* .nasaha{ */
/* background: url('../assets/bglast.png');background-size: cover; */
/* } */
.nasaha .block {
	background-color: rgba(255, 255, 255, 0.2);
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.nasaha .block .content-link {
	width: 100%;
	text-decoration: none;
	display: block;
}
.nasaha .block .carousel__item {
	height: 100px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	color: #19bc9d;
	font-size: 22px;
	border-radius: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: quran;
}

.darsa .carousel__item {
	min-height: 200px;
	width: 100%;
	/* color:  white; */
	font-size: 20px;
	border-radius: 0px;
	justify-content: left;
	font-family: quran;
	text-align: left;
	/* padding-left: 30px; */
}
.darsa .title {
	font-size: 25px;
}
.darsa p {
	padding-left: 30px;
}
.carousel__slide {
	padding: 0px;
}
.nasaha .carousel__prev,
.nasaha .carousel__next {
	background-color: #19bc9d !important;
	border: 4px solid white;
}
.darsa .carousel__prev,
.darsa .carousel__next {
	background-color: white !important;
	border: 4px solid #19bc9d;
	color: #19bc9d;
}
.carousel__pagination button {
	color: red;
}
</style>
