<template>
	<section class="footer-basic">
		<footer>
			<div class="social">
				<a
					href="https://www.facebook.com/attamayyuz.attamayyuz.1"
					target="_blank"
					><i class="fa fa-facebook"></i
				></a>
				<a href="https://twitter.com/attamayyuz" target="_blank"
					><i class="fa fa-twitter"></i
				></a>
				<a
					href="https://www.instagram.com/attamayyuz.or.tz/"
					target="_blank"
					><i class="fa fa-instagram"></i
				></a>
			</div>
			<!-- <ul class="list-inline">
				<li class="list-inline-item"><a href="#">Terms</a></li>
				<li class="list-inline-item"><a href="#">Privacy Policy</a></li>
			</ul> -->
			<p class="copyright">
				© 2019 - 2021 attamayyuz.or.tz All rights reserved
			</p>
		</footer>
	</section>
</template>
<script>
export default {};
</script>
<style scoped>
.footer-basic {
	margin-top: 20px;
	padding: 30px 0;
	background-color: #19bc9d;
	color: white;
	clear: both;
}

.footer-basic ul {
	padding: 0;
	list-style: none;
	text-align: center;
	font-size: 18px;
	line-height: 1.6;
	margin-bottom: 0;
}

.footer-basic li {
	padding: 0 10px;
}

.footer-basic ul a {
	color: inherit;
	text-decoration: none;
	opacity: 1;
}

.footer-basic ul a:hover {
	opacity: 1;
}

.footer-basic .social {
	text-align: center;
	padding-bottom: 25px;
}

.footer-basic .social > a {
	font-size: 24px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	border: 1px solid #ccc;
	margin: 0 8px;
	color: #19bc9d;
	background: #fffdf5;;
	opacity: 1;
}

.footer-basic .social > a:hover {
	opacity: 0.93;
}

.footer-basic .copyright {
	margin-top: 15px;
	text-align: center;
	font-size: 13px;
	color: white;
	margin-bottom: 0;
}
</style>
