<template>
	<base-drawer>
		<router-view class="content"></router-view>
	</base-drawer>
</template>

<script>
export default {
	name: 'App',
	components: {},
};
</script>

<style>
* {
	text-decoration: none;
}
ol, ul {
    padding-left: 0rem !important;
	margin: 0px !important;
}
body,
html {
	color: #333;
	background: #fafafa url('./assets/bglast.png');
	background-size: 50px;
	font-family: sans-serif;
	margin: 0;
	padding: 0;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: dimgray;
}

body {
	overflow-x: auto;
}


.bg-main {
	background: #19bc9d;
}
.bg-secondary {
	background: #6184c6;
}
.bg-blue {
	background: #1d416dbe;
}
.bg-super{
	background: #fffdf5;
}
.bg-trans{
	background: rgba(255, 253, 245, .2);
}
.txt-main {
	color: #19bc9d;
}
.txt-secondary {
	color: #6184c6;
}
.txt-brown {
	color: #d05663;
}
.siteLoader {
	margin-top: 0vh;
}

.form-control {
	font-size: 15px;
}
.form-control,
.form-control:focus,
.input-group-text {
	border-color: #e1e1e1;
}
.form-control,
.btn {
	min-height: 38px;
	border-radius: 2px;
	box-shadow: none;
}


@import '//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.css';
.title-container .screen-reader-text {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.title-container .disabled {
	color: #666;
	cursor: default;
}

.title-container .show {
	display: inline-block !important;
}

.title-container {
	font-family: arial, helvetica, sans-serif;
	margin: 0 auto;
	margin-top: 0px;
	padding-bottom: 90px;

}
.title-container section {
	bottom: -53px;
}
.title-container .play-list .play-list-row {
	position: relative;
	word-wrap: break-word;
	overflow: hidden;
	height: 40px;
	line-height: 40px;
	font-size: 16px;
	margin: 10px;
	padding-left: 5px;
}
.title-container .play-list .play-list-row .small-toggle-btn {
	position: absolute;
	top: 0px;
	border: 0px solid #d9d9d9;
	border-radius: 0px;
	cursor: pointer;
	display: inline-block;
	height: 100%;
	line-height: 40px;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 30px;
	color: darkcyan;
}

.title-container .play-list .play-list-row .small-toggle-btn .small-play-btn {
	display: inline-block;
}
.title-container
	.play-list
	.play-list-row
	.small-toggle-btn
	.small-play-btn:before {
	content: '\f04b';
	font: 0.85em 'FontAwesome';
}
.title-container .play-list .play-list-row .small-toggle-btn .small-pause-btn {
	display: inline-block;
}
.title-container
	.play-list
	.play-list-row
	.small-toggle-btn
	.small-pause-btn:before {
	content: '\f04c';
	font: 0.85em 'FontAwesome';
}
.title-container .audioTitle div {
	font-size: 15px;
}
.title-container .play-list .play-list-row .track-title a {
	display: block;
	position: absolute;
	top: 0px;
	right: 0px;
	left: 36px;
	bottom: 0px;
	line-height: 40px;
	padding-left: 5px;
	text-transform: uppercase;
}
.title-container .play-list .play-list-row .track-title.active-track a {
	color: red;
}
.title-container .play-list .play-list-row .track-title .playlist-track {
	color: rgba(0, 0, 0, 0.8);
	text-decoration: none;
	display: block;
}
.title-container .play-list .play-list-row .track-title .playlist-track:hover {
	text-decoration: none;
}
.title-container .play-list .play-list-row .track-title .activeDot, 
.title-container .play-list .arabic {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px;
	color: red;
}
.title-container .play-list .play-list-row .track-title .activeDot{
	display: none;
}
.title-container
	.play-list
	.play-list-row
	.track-title.active-track
	.activeDot {
	display: block;
}
.scale_motion {
	animation-name: track;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

@keyframes track {
	0% {
		padding-top: 3px;
	}
	50% {
		padding-top: 18px;
	}
	100% {
		padding-top: 3px;
	}
}

.btnDownload {
	position: absolute;
	top: 40px;
	right: 30px;
	width: 40px;
	height: 40px;
	background: #19bc9d;
	color: white;
	text-align: center;
	transition: 0.4s;
	cursor: pointer;
	line-height: 40px;
}

.audioTitle{
	margin-top: 20px;
}
.audioTitle:hover .btnDownload {
	top: 0px;
}
.title-container .player {
	position: fixed;
	left: 0px;
	right: 0px;
	background: #fff;
	height: 70px;
	transition: 0.3s;
	bottom: -70px;
	*zoom: 1;
}


.title-container .player.showPlayer,
.title-container section.showPlayer {
	bottom: 0px;
	background: #f5f5f5;
	box-shadow: 0 0 1px darkcyan inset;
}
.title-container .player .sub-container {
	position: relative;
	width: 100%;
	max-width: 720px;
	height: 65px;
	margin: 0 auto;
}
.title-container .player .large-toggle-btn {
	border: 1px solid #d9d9d9;

	position: absolute;
	font-size: 8px;
	overflow: hidden;
	padding: 0px;
	position: absolute;
	left: 34px;
	text-align: center;
	width: 35px;
	height: 35px;
	border-radius: 40px;
	line-height: 35px;
	background: darkcyan;
	color: white;
	bottom: 0px;
}
.title-container .player .large-toggle-btn .large-play-btn {
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.title-container .player .large-toggle-btn .large-play-btn:before {
	content: '\f04b';
	font: 1.5em/1.75 'FontAwesome';
}
.title-container .player .large-toggle-btn .large-pause-btn {
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.title-container .player .large-toggle-btn .large-pause-btn:before {
	content: '\f04c';
	font: 1.5em/1.75 'FontAwesome';
}
.title-container .player .info-box {
	bottom: 1px;
	left: 0px;
	right: 0px;
	position: absolute;
	height: 43px;
	border-radius: 2px;
}
.title-container .player .info-box .track-info-box {
	float: left;
	font-size: 12px;
	visibility: hidden;
	width: 100%;
	text-align: center;
	*zoom: 1;
	height: 11px;
}
.title-container .player .info-box .track-info-box .track-title-text {
	position: absolute;
	top: -10px;
	color: darkcyan;
	width: 100%;
}
.title-container .player .info-box .track-info-box .audio-time {
	position: absolute;
	top: 20px;
	right: 0px;
	width: 100px;
	color: darkcyan;
}
.title-container .player .info-box .track-info-box:before,
.title-container .player .info-box .track-info-box:after {
	content: ' ';
	display: table;
}
.title-container .player .info-box .track-info-box:after {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}
.title-container .player .progress-box {
	float: left;
	position: absolute;
	left: 120px;
	right: 100px;
}
.title-container .player .progress-box .progress-cell {
	height: 12px;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 20px;
}
.title-container .player .progress-box .progress-cell .progress {
	background: white;
	border: 1px solid #d9d9d9;
	height: 10px;
	width: auto;
}
.title-container
	.player
	.progress-box
	.progress-cell
	.progress
	.progress-buffer {
	background: darkcyan;
	height: 100%;
	width: 0;
	border-radius: 2px;
}
.title-container
	.player
	.progress-box
	.progress-cell
	.progress
	.progress-indicator {
	background: darkcyan;
	border: 3px solid white;
	border-radius: 12px;
	cursor: pointer;
	height: 17px;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: -3px;
	width: 17px;
	box-shadow: 0 0 2px rgb(92, 74, 74);
}
.title-container .player .controls-box {
	bottom: 10px;
	left: 0px;
	position: absolute;
	width: 100px;
	height: 30px;
	bottom: 0px;
}
.title-container .player .controls-box .previous-track-btn {
	cursor: pointer;
	display: inline-block;
	color: darkcyan;
	position: absolute;
	left: 5px;
}
.title-container .player .controls-box .previous-track-btn:before {
	content: '\f049';
	font: 1em 'FontAwesome';
}
.title-container .player .controls-box .next-track-btn {
	cursor: pointer;
	display: inline-block;
	margin-left: 20px;
	color: darkcyan;
	position: absolute;
	right: 0px;
}
.title-container .player .controls-box .next-track-btn:before {
	content: '\f050';
	font: 1em 'FontAwesome';
}
.title-container .player:before,
.title-container .player:after {
	content: ' ';
	display: table;
}
.title-container .player:after {
	clear: both;
	display: block;
	font-size: 0;
	height: 0;
	visibility: hidden;
}

.options {
	width: 100%;
	max-width: 900px;
	display: flex;
	margin: 0 auto;
	padding: 3px;
	margin-top: 10px;
}
.options *{
	text-transform: uppercase;
}
/* .options select {
	-moz-text-transform: lowercase;
	-o-text-transform: lowercase;
	-webkit-text-transform: lowercase;
	text-transform: lowercase;
	margin: 4px;
}
.options select option {
	width: 48%;
	padding: 3px;
	color: darkcyan;
	margin-left: 1%;
	-moz-text-transform: uppercase;
	-o-text-transform: uppercase;
	-webkit-text-transform: uppercase;
	text-transform: uppercase;
} */
input:focus,
select:focus,
textarea:focus {
	-moz-outline: none;
	-o-outline: none;
	-webkit-outline: none;
	outline: none;
	box-shadow: none !important;
}

@font-face {
  font-family: 'quran';
  src: URL('assets/fonts/qurans2.ttf') format('truetype');
}
.ql-align-right{
	text-align: right;
}

</style>
