<template>
	<section class="wraper mt-3">
		<div class="tab" style="width: 100%; max-width: 1000px; margin: 0 auto">
			<w-tabs :items="tabs" center v-model="currentTab" active-class="bg-trans" class="mb10" >
				<template #item-title="{ item }">
					<w-icon class="mr1 txt-main">{{item.icon}}</w-icon>
					<span class="primary ml4">{{ item.title }}</span>
				</template>
				<!-- <template #item-title.3="{ item }">
					<w-icon class="pink">mdi mdi-heart</w-icon><span class="pink mx1">{{ item.title }}</span>
					<w-icon class="pink">mdi mdi-heart</w-icon>
				</template> -->

				<!-- MY QUESTIONS AND ALL QUESTIONS -->
				<template #item-content="">
					<div class="title1">
						<div class="container-fluid text-left question" v-if="currentTab==0 || currentTab==1">
							<w-button class="allquestion ma0" v-if="question.length>0 && isOpen !== null" @click="isOpen = null">All Question</w-button>
							<div class="row" v-for="(q,index) in question" :key="q.id" >
								<card-content class="col-sm-12 question-block" style="margin: 5px 0px;position: relative;" @click="isOpen = (isOpen == 'block' + index) ? null : 'block' + index" v-if="isOpen=='block' + index || isOpen==null">
										<div class="text-bold text-capitalize pa0 ma0 title">{{ q.question_subject }}</div>
										<span style="position: absolute;top: 2px;right: 10px;">
											<span class="fa fa-refresh fa-spin orange" v-if="!q.answer"> </span>
											<span class="fa fa-check green" v-if="q.answer"> </span>
										</span>
										<div v-if="isOpen == 'block' + index" class="answerBlock text-justify">
											<p class="primary">{{q.question_desc}}</p>
											<hr v-if="q.answer" />
											<p v-if="q.answer" v-html="q.answer" style="font-family: quran;font-size: 25px;letter-spacing: 1px;"></p>
										</div>
								</card-content>
								<div class="container bg-super" style="width: 100%; max-width: 1000px; margin: 0px 0 auto;padding: 0px;" v-if="!q.answer && isOpen=='block'+index && currentTab == 0">
									<quill-editor v-model:content="answerContent" contentType="html" placeholder="Andika jibu hapa" class="" theme="snow" toolbar="essential"></quill-editor>
									<w-button class="bg-main" :loading="isPosting" @click="sendAnswer(index, q)">Tuma Jawabu</w-button>
								</div>
							</div>
							<div v-if="question.length==0 && !isLoading" class="text-danger h6 text-center">
								<p class="mt-5 mb-5">
									Hakuna swali lililoulizwa
								</p>
								
							</div>
							<div class="text-center">
								<vue-loaders
									class="siteLoader"
									name="line-scale"
									color=" #19bc9d"
									scale=".9"
									style="margin: 20px auto;"
									v-if="isLoading"
								></vue-loaders>
							</div>
						</div>

						<!-- ASK QUESTION -->
						<div class="container text-center" v-else-if="currentTab==2">
							<div class="container">
								<div class="row text-left">
									<div class="col-md-12">
										<div
											class="alert alert-danger text-left p-1 m-1"
											v-for="(err, index) in error"
											:key="index"
										>
											{{ err }}
										</div>
										<div class="question-form">
											<form
												action="#"
												method="post"
												@submit.prevent="postQuestion()"
											>
												<div class="form-group">
													<label for="inputSubject"
														>Kichwa Cha Habari</label
													>
													<input
														type="text"
														class="form-control"
														required
														v-model="title"
														style="width: 100%;padding: 10px;"
													/>
												</div>
												<div class="form-group">
													<label for="inputMessage"
														>Swali</label
													>
													<textarea
														class="form-control"
														rows="5"
														required
														v-model="detail"
													></textarea>
												</div>

												<w-button
													type="submit"
													class="btn btn-primary"
													:loading="isPosting"
												>
													<w-icon>mdi mdi-check</w-icon>
													Send
												</w-button>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</w-tabs>
		</div>
	
		<w-notification
			style="z-index:99999999"
			v-model="notification.show"
			:timeout="notification.timeout"
			success
			plain="plain"
			top
			shadow="shadow"
			transition="scale-fade"
			>{{ notification.msg }}</w-notification
		>
		<w-notification
			style="z-index:99999999"
			v-model="notification.showError"
			:timeout="notification.timeout"
			error
			plain="plain"
			top
			shadow="shadow"
			transition="scale-fade"
			>{{ notification.msg }}</w-notification
		>
		<!--
			Toolbar
			- minimal
			- essential
			- full
			-->
		
		
	</section>
</template>
<script>

 

import axios from "axios";
export default {
	data() {
		return {
			tabs: [
				{ title: 'Maswali Na Majibu', content: 'Tab 1 content.', icon: 'fa fa-info' },
				{ title: 'Maswali Yangu', content: 'Tab 2 content.', icon: 'fa fa-user' },
				{ title: 'Uliza Swali', content: 'Tab 3 content.', icon: 'fa fa-question' }
			],
			currentTab:-1,
			answerContent:"",
			title: '',
			detail: '',
			statusCode: null,
			isOpen: null,
			question: [],
			isLoading: false,
			isPosting: false,
			isQuestion: true,
			askQuestion: false,
			noQuestion: false,
			resultCode: null,
			error: [],
			notification: {
				show: false,
				showError: false,
				timeout: 4000,
				msg: '',
				position: ['true', 'right'],
			},
		};
	},
	methods: {
		sendAnswer(questionindex, question){
			
			if (!this.isPosting) {
				if(this.answerContent!='' && question.question_id > 0){
					this.isPosting = true;
					this.error = [];
					let obj = {title:question.question_subject,detail: question.question_desc, answer:this.answerContent, question:question.question_id, user: question.user_id};
					fetch(this.globalUrl + 'replyquestion', {
						headers: {
							'Content-Type': 'application/json',
							Authorization:
								'Bearer ' + localStorage.getItem('token'),
						},
						method: 'POST',
						body: JSON.stringify(obj),
					})
						.then((response) => {
							this.statusCode = response.status;
							if (response.ok) {
								return response.json();
							}
						})
						.then((result) => {
							this.isPosting = false;
							let code = this.statusCode;
							if (code == 200) {
								this.error = result.errors;
							} else if (code == 201) {
								this.notification.msg =
									'Answer Added Successfull';
								this.notification.show = true;
								this.isOpen = null;
								this.currentTab = 0;
								this.question[questionindex].answer = this.answerContent;
								this.answerContent = '';
							}
						})
						.catch(() => {
							this.isPosting = false;
							this.notification.msg =
								'Fail To Reply, Please Retry Again Later';
							this.notification.showError = true;
						});
				}
				else{
					this.notification.msg = 'Please Enter Answer';
					this.notification.showError = true;
				}
			}
		},
		questionStatus(status) {
			return status ? { value: 'Answered' } : { value: 'Not Answered' };
		},
		logout() {
			if (!this.isOut()) {
				this.clearStore();
				this.$router.push('/login');
			} else {
				this.clearStore();
				this.$router.push('/login');
			}
		},
		clearStore() {
			this.$store.state.isLogin = false;
			localStorage.removeItem('isLogin');
			localStorage.removeItem('token');
			localStorage.removeItem('loginUser');
		},
		isOut() {
			return (
				localStorage.getItem('isLogin') == null ||
				localStorage.getItem('isLogin') != 'true'
			);
		},
		askNavigator() {
			this.askQuestion = true;
			this.isQuestion = false;
		},
		questionNavigator() {
			this.isQuestion = true;
			this.askQuestion = false;
		},
		getObject() {
			return {
				subject: this.title,
				body: this.detail,
			};
		},
		postQuestion() {
			if (!this.isPosting) {
				this.isPosting = true;
				this.error = [];
				axios.post('question', this.getObject(),{
					headers: {
						'Content-Type': 'application/json',
						Authorization:
							'Bearer ' + localStorage.getItem('token'),
					}
				})
				.then( result => {
					this.isPosting = false;
					let code = result.status;
					if (code == 200) {
						this.error = result.errors;
					} else if (code == 201) {
						this.question.push({
							question_subject: this.title,
							question_desc: this.detail,
							status: 0,
							answer: [],
						});
						this.notification.msg =
							'Question Added Successfull, wait for answer';
						this.notification.show = true;
						this.title = '';
						this.detail = '';
					}
				})
				.catch(() => {
					this.isPosting = false;
					this.notification.msg =
						'An Error Occured, Check Your Network';
					this.notification.showError = true;
				});
			}
		},
		getQuestion(url) {
			this.isLoading = true;
			axios.get(url, {
				headers: {
					"Authorization": 'Bearer ' + localStorage.getItem('token'),
				},
			})
				.then((response) => {
					let result = response.data;
					this.isLoading = false;
					let code = response.status;
					if (code == 201) {
						this.noQuestion = false;
						this.question = result;
					} else if (code == 200) {
						this.noQuestion = true;
					} else if (code == 401) {
						this.noQuestion = true;
						this.notification.msg =
							'You Are Not Authorized To Get This Data';
						this.notification.showError = true;
						this.logout();
					}
				})
				.catch((error) => {
					let status = error.response.status;
					if(status==401){
						this.logout();
					}
					this.isLoading = false;
					this.notification.msg =
						'An Error Occured, Check Your Network';
					this.notification.showError = true;
				});
		},
	},
	computed: {
		getLogin() {
			let obj = { name: 'User' };
			return this.$store.state.user ?? obj;
		},
	},
	watch:{
		currentTab(){
			this.isOpen = null;
			let url = this.currentTab==0?'question':(this.currentTab==1?'myquestion':null);
			if(url){
				this.question = [];
				this.getQuestion(url);
			}
		}
	},
	created() {
		if (this.isOut()) {
			this.logout();
		} else {
			this.$store.state.user = localStorage.getItem('loginUser')
			this.currentTab = 0;
		}
	},
};
</script>
<style scoped>
.body {
	position: absolute;
	background: #f1f1f1;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
}
.question .question-block {
	border-radius: 0px;
	transition: 2s;
}
.question .question-block .title {
	font-weight: bold;
	color: #1d416d;
	font-size: 14px;
}
.question .question-block .details {
	text-align: justify;
}
.ask-question {
	position: absolute;
	top: inherit;
	width: 100%;
	margin-top: 15px;
}

.question-form {
	padding: 10px 20px;
	margin: 10px 0;
	background: white;
}

.question-form .btn-primary,
.question-form .btn-primary:active {
	color: #fff;
	min-width: 100px;
	font-size: 16px;
	background: #19bc9d !important;
	border: none;
}
.question-form .btn-primary:hover {
	background: #15a487 !important;
}
.question-form .btn i {
	margin-right: 5px;
}
.question-form label {
	opacity: 0.7;
}
.question-form textarea {
	resize: vertical;
}
.hint-text {
	font-size: 15px;
	padding-bottom: 20px;
	opacity: 0.6;
}

.form-control,
.form-control:focus,
.input-group-text {
	border-color: #e1e1e1;
}
.form-control,
.btn {
	min-height: 38px;
	border-radius: 2px;
	box-shadow: none;
}
.question-form label {
	color: #1d416d;
	font-size: 16px;
}

.siteLoader {
	padding: 50px 0px;
	margin: 0 auto;
}
</style>
