<template>
	<section class="container">
		<nav aria-label="breadcrumb" class="mt-3">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link to="/" class="primary">Home</router-link>
				</li>
				<li class="breadcrumb-item">
					<router-link to="/quran" class="primary">Quran</router-link>
				</li>
				<li
					class="breadcrumb-item active text-bold"
					style="color: #19bc9d"
					aria-current="page"
				>
					Abdulrahman Assudais
				</li>
			</ol>
		</nav>
		<div class="title-container">
			<div
				style="display: none; padding: 0px; margin: 0px; width: 100%"
				class="audioError"
			>
				<w-alert
					error
					icon-outside
					color="error"
					style="margin-left: 0px"
					><div>Error</div></w-alert
				>
			</div>

			<section>
				<div class="play-list row">
					<div
						style="padding: 0px"
						class="audioTitle col-lg-4 col-md-6"
						v-for="(audio, index) in audioList"
						:key="audio.surah_id"
					>
						<card-content
							style="margin: 0px 10px"
							class="play-list-row"
							:data-track-row="generateIndex(index)"
						>
							<div class="small-toggle-btn">
								<i class="small-play-btn"
									><span class="screen-reader-text"
										>Small toggle button</span
									></i
								>
							</div>
							<div class="track-number"></div>
							<div class="track-title">
								<a
									class="playlist-track"
									href="#"
									:data-play-track="generateIndex(index)"
									@click="playNow()"
								>
									<span>{{
										convertNumber(audio.surah_number) +
										' : ' +
										audio.surah_name
									}}</span></a
								>

								<span
									class="fa fa-bullseye activeDot scale_motion"
								></span>
							</div>
							<a
								:href="
									'https://resources.attamayyuz.or.tz/download.php?t=q&file=' +
									convertNumber(audio.surah_number) +
									'.mp3&name=' +
									audio.surah_name
								"
							>
								<div class="btnDownload">
									<i class="fa fa-download"></i>
								</div>
							</a>
						</card-content>
						<!-- <span
									class="arabic"
								>{{audio.arabic_name}}</span> -->
					</div>
				</div>
			</section>
			<section
				:class="{ showPlayer: showPlayer }"
				style="
					position: fixed;
					left: 0px;
					right: 0px;
					height: 53px;
					box-shadow: 0 0 2px cyan inset;
				"
			>
				<div :class="{ player: true, showPlayer: showPlayer }">
					<div class="sub-container">
						<div class="info-box">
							<div class="track-info-box">
								<div class="track-title-text"></div>
								<div class="audio-time">
									<span class="current-time">00:00</span> /
									<span class="duration">00:00</span>
								</div>
							</div>
							<!-- /.info-box -->

							<div class="progress-box">
								<div class="progress-cell">
									<div class="progress">
										<div class="progress-buffer"></div>
										<div class="progress-indicator"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="controls-box">
							<i class="previous-track-btn disabled"
								><span class="screen-reader-text"
									>Previous track button</span
								></i
							>
							<i class="next-track-btn"
								><span class="screen-reader-text"
									>Next track button</span
								></i
							>
						</div>

						<div class="large-toggle-btn">
							<i class="large-play-btn"
								><span class="screen-reader-text"
									>Large toggle button</span
								></i
							>
						</div>
						<!-- /.play-box -->
					</div>
				</div>
				<audio id="audio" preload="none" tabindex="0">
					<source
						v-for="(audio, index) in audioList"
						:key="audio.surah_id"
						:src="audioPath(audio.surah_number)"
						:data-track-number="generateIndex(index)"
					/>
					Your browser does not support HTML5 audio.
				</audio>
			</section>
		</div>
		<vue-loaders
			class="siteLoader"
			name="line-scale"
			color="#19bc9d"
			scale=".9"
			v-if="isLoading"
		></vue-loaders>
	</section>
</template>
<script>
import cookie from 'vue-cookies';
import audioPlayer from '../player';
import axios from 'axios';
export default {
	data() {
		return {
			isLoading: false,
			audioList: [],
			cookieFound: false,
			breadShekh: '',
			breadType: '',
			breadTitle: '',
			showPlayer: false,
			loadScript: '',
			items: [
				{ label: 'Home', route: '/' },
				{ label: 'Quran', route: '/quran' },
				{ label: 'Abdulrahman Assudais', route: '' },
			],
		};
	},
	methods: {
		playNow() {
			document.querySelector('.audioError').style = 'display:none;';
			this.showPlayer = true;
		},
		generateIndex(index) {
			return ++index;
		},
		audioPath(filename) {
			cookie.set('confq', { qari_id: 1 });
			let qari = cookie.get('confq').qari_id;
			let name = this.convertNumber(filename) + '.mp3';
			return (
				'https://resources.attamayyuz.or.tz/audio/quran/' +
				qari +
				'/' +
				name
			);
		},
		convertNumber(number) {
			var x = `` + number + ``;
			if (x.length == 1) {
				return '00' + number;
			} else if (x.length == 2) {
				return '0' + number;
			} else {
				return number;
			}
		},
		download(file, fName) {
			window.location.href =
				'https://resources.attamayyuz.or.tz/download.php?file=' +
				this.convertNumber(file) +
				'.mp3&name=' +
				fName;
		},
	},
	computed: {
		getDot() {
			return this.$store.state.activeDote;
		},
	},
	watch: {
		loadScript() {
			setTimeout(function () {
				var player = new audioPlayer();
				player.initPlayer();
			}, 1000);
		},
	},
	created() {
		if (
			cookie.isKey('confs') &&
			cookie.get('confs') != null &&
			cookie.isKey('confd')
		) {
			this.breadShekh = cookie.get('confs').shekh_name;
			this.breadType = cookie.get('confd').type_name;
			this.breadTitle = cookie.get('conft');
			this.cookieFound = true;
		}

		this.isLoading = true;
		axios
			.post('quran')
			.then((response) => {
				if (response.data) {
					this.audioList = response.data;
					this.isLoading = false;
					this.loadScript = 1;
				}
			})
			.catch(() => {
				this.isLoading = false;
			});
	},
};
</script>
<style scoped>
.play-list {
	padding: 0px;
	margin: 0px;
}

.title-container .play-list .arabic {
	position: absolute;
	top: 13px;
	right: 80px;
	color: darkcyan;
	font-family: quran;
	font-size: 30px;
	transition: 1s;
	height: 10px;
	line-height: 0px;
}

/* .audioTitle:hover .arabic{
	background: red;
	top: -22px;
} */
.breadcrumb {
	position: relative;
	background: transparent;
	-webkit-box-shadow: 0px 5px 10px -17px brown;
	-moz-box-shadow: 0px 5px 10px -17px brown;
	-o-box-shadow: 0px 5px 20px -17px brown;
	box-shadow: 0px 5px 17px -17px brown;
}

.breadcrumb li {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	word-spacing: 3px;
}
.breadcrumb li a {
	text-decoration: none;
}
</style>
