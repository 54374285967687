<template>
	<section>
		
		<div class="login-form">
			<w-alert
					warning
					icon-outside
					color="warning"
					style="margin: 10px 0px;position: absolute;width:inherit;top: -10px;"
					class="pa1"
					v-if="notification.show"
					><div>{{notification.msg}}</div></w-alert
				>
			<form
				action="#"
				method="post"
				autocomplete="off"
				@submit.prevent="login()"
				class="bg-super"
			>
				<!-- <h2 class="text-center">Log in</h2> -->
				<div class="form-group">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-paper-plane"></i>
							</span>
						</div>
						<input
							type="email"
							class="form-control"
							name="email"
							placeholder="Barua pepe"
							required="required"
							v-model="email"
						/>
					</div>
				</div>
				<div class="form-group">
					<div class="input-group">
						<div class="input-group-prepend">
							<span class="input-group-text">
								<i class="fa fa-lock"></i>
							</span>
						</div>
						<input
							type="password"
							class="form-control"
							name="password"
							placeholder="Neno siri"
							required="required"
							v-model="pass"
						/>
					</div>
				</div>
				<div class="form-group">
					<!-- <vue-loaders
						class="siteLoader"
						name="line-scale"
						color="#19bc9d"
						scale=".8"
						v-if=""
					></vue-loaders> -->
					<w-button type="submit" class="btn btn-block login" :loading="isLoading">
						INGIA
					</w-button>
				</div>
				<!-- <div class="clearfix">
					<router-link to="/recover" class="align-center text-danger"
						>Forgot Password ?</router-link
					>
				</div> -->
			</form>
			<div class="form-group">
				<button
					type="submit"
					class="btn btn-block register"
					@click="register()"
				>
					JISAJILI
				</button>
			</div>
		</div>

		<w-notification
			v-model="notification.show"
			:timeout="notification.timeout"
			error
			plain="plain"
			bottomright
			shadow="shadow"
			transition="scale-fade"
			>{{ notification.msg }}</w-notification
		>
	</section>
</template>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			email: '',
			pass: '',
			isLoading: false,
			notification: {
				show: false,
				timeout: 7000,
				msg: '',
				position: ['true', 'bottom'],
			},
		};
	},
	methods: {
		register() {
			this.$router.push('/register');
		},
		login() {
			this.isLoading = true;
			// this.notification.msg = 'Accessed';
			// this.notification.show = true;
			if (this.email != '' && this.pass != '') {
				axios
					.post(
						'log',
						{
							email: this.email,
							password: this.pass,
						},
						{
							headers: {
								'Content-Type': 'application/json',
								'Accept': 'application/json',
							},
						}
					)
					.then((data) => {
						this.isLoading = false;
						
						// alert(data.status)
						// alert(JSON.stringify(result));
						if (data.status === 201) {
							let result = data.data;
							localStorage.setItem('token', result.token);
							this.$store.state.isLogin = true;
							this.$store.commit(
								'setUser',
								JSON.stringify(result.user)
							);
							this.$router.push('/dashboard');
						}else {
							this.notification.msg =
								'Invalid Credential';
							this.notification.show = true;
						}
					})
					.catch((err) => {
						this.isLoading = false;

						if(err.response && err.response.status==403){
							this.notification.msg =
								'Invalid Credential';
							this.notification.show = true;
						}
						else{
							this.notification.msg =
								'Network Error';
							this.notification.show = true;
						}
						
					});
			} else {
				this.notification.msg =
					'Please Enter Username And Password !!!';
				this.notification.show = true;
			}
		},
		isLogin() {
			return (
				localStorage.getItem('isLogin') != null &&
				localStorage.getItem('isLogin') === 'true'
			);
		},
	},

	created() {
		if (this.isLogin()) {
			this.$router.push('/dashboard');
		}
	},
};
</script>
<style scoped>
.login-form {
	width: 340px;
	margin: 0px auto;
	font-size: 15px;
	padding-top: 3%;
	margin-top: 30px;
}
.login-form form {
	margin-bottom: 15px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
}
.login-form h2 {
	margin: 0 0 15px;
	color: #19bc9d;
}
.form-control,
.form-control:focus,
.input-group-text {
	border-color: #e1e1e1;
}
.form-control,
.btn {
	min-height: 38px;
	border-radius: 2px;
	box-shadow: none;
}
.btn {
	font-size: 15px;
	font-weight: bold;
	color: white;
}
.login-form a {
	text-decoration: none;
}
.btn.login {
	background: #19bc9d;
}
.btn.register {
	background: #f7f7f7;
	color: #1d416d;
	box-shadow: 0px 1px 3px #19bc9d;
}
.login-form .fa {
	font-size: 18px;
	color:#19bc9d;
}
.input-group-prepend span i {
	width: 18px;
}
.input-group-text{
	background: white;
	color: #19bc9d;
}
.recover {
	color: #1d416d;
}
a.back {
	text-decoration: none;
	color: brown;
}
</style>
