<template>
	<div>
		<header v-if="$slots.header">
			<slot name="header"></slot>
		</header>
		<slot></slot>
	</div>
</template>

<style scoped>
header {
	background: #712e1e;
	color: darkcyan;
}
div {
	margin: 5px;
	box-shadow: 0 0 1px gray inset;
	border-radius: 2px;
	border-left: 3px solid darkcyan;
	min-height: 40px;
	line-height: 39px;
	cursor: pointer;
	word-spacing: 4px;
	letter-spacing: 1px;
	padding: 0px 20px;
	text-align: left;
	color: rgba(0, 0, 0, .7);
	background: rgba(255, 255, 255, .3);
	font-weight: 100;
	font-size: 15px;
}
</style>
