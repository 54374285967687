<template>
	<section>
		<div class="wraper ma2 mt6">
			<w-card shadow class="bg-trans">
				<p style="padding: 10px;margin: 0px;">
					<b>Attamayyuz</b> ni mtandao wa kiislamu ulioanzishwa kwa
					lengo la kuulingania uislam, kuwaelimisha waislam ili waijue
					dini yao kwa usahihi kupitia kitabu kitukufu cha Quran na
					mafundisho sahihi ya Mtume Swallallahu alayhi wa sallam
					(Sunah). Mtandao utatumika katika kutawanya duruus, makala,
					vitabu, nasaha na mawaidha ya mashekhe na wanafunzi
					mbalimbali waliosoma nchini Saudi Arabia, Yaman, Afrika
					Mashariki na kadhalika wenye itikadi na manhaji sahihi.
				</p>
			</w-card>
		</div>
		<site-footer></site-footer>
	</section>
</template>
<style scoped>
.wraper{
	min-height: 80vh;
}
section p {
	text-align: justify;
	padding: 20px;
	letter-spacing: 2px;
	font-size: 16px;
	color: rgba(54, 49, 49, 0.8);
	margin-top: 20px;
}
section p b{
	color: darkcyan;
	font-family: quran;
	font-size: 20px;
	letter-spacing: 3px;
}
</style>
