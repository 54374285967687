<template>
	<section class="container">
		<w-flex class="options row">
			<w-select
				:items="shekhList"
				:placeholder="loadShekhLabel"
				v-model="selectedShekh"
				inner-icon-left="mdi mdi-account"
				:value="selectedShekh"
				outline
				bg-color="blue-light5"
				class="sm6"
			/>
		</w-flex>
		<div class="title-container">
			<div
				style="display: none; padding: 0px; margin: 0px; width: 100%"
				class="audioError"
			>
				<w-alert error icon-outside color="error" style="margin: 10px"
					><div>Error</div></w-alert
				>
			</div>
			<section v-if="!isLoading">
				<div class="play-list row">
					<div
						style="padding: 0px"
						class="audioTitle col-lg-12 col-md-6"
						v-for="(title, index) in titleList"
						:key="title.mhadhara_id"
					>
						<card-content
							style="margin: 0px 5px"
							class="play-list-row"
							:data-track-row="generateIndex(index)"
							@click="playNow()"
						>
							<div class="small-toggle-btn">
								<i class="small-play-btn"
									><span class="screen-reader-text"
										>Small toggle button</span
									></i
								>
							</div>
							<div class="track-number"></div>
							<div class="track-title text-uppercase">
								<a
									class="playlist-track"
									href="#"
									:data-play-track="generateIndex(index)"
								>
									{{ title.mhadhara_title }}</a
								>
								<span
									class="fa fa-bullseye activeDot scale_motion"
								></span>
							</div>
							<div
								class="btnDownload"
								@click="
									downloadAudio(
										title.mhadhara_file,
										title.mhadhara_title
									)
								"
							>
								<i class="fa fa-download"></i>
							</div>
						</card-content>
					</div>
				</div>
			</section>
			<section
				:class="{ showPlayer: showPlayer }"
				style="
					position: fixed;
					left: 0px;
					right: 0px;
					height: 53px;
					box-shadow: 0 0 2px cyan inset;
				"
			>
				<div :class="{ player: true, showPlayer: showPlayer }">
					<div class="sub-container">
						<div class="info-box">
							<div class="track-info-box">
								<div class="track-title-text"></div>
								<div class="audio-time">
									<span class="current-time">00:00</span> /
									<span class="duration">00:00</span>
								</div>
							</div>
							<!-- /.info-box -->

							<div class="progress-box">
								<div class="progress-cell">
									<div class="progress">
										<div class="progress-buffer"></div>
										<div class="progress-indicator"></div>
									</div>
								</div>
							</div>
						</div>

						<div class="controls-box">
							<i class="previous-track-btn disabled"
								><span class="screen-reader-text"
									>Previous track button</span
								></i
							>
							<i class="next-track-btn"
								><span class="screen-reader-text"
									>Next track button</span
								></i
							>
						</div>
						<div class="large-toggle-btn">
							<i class="large-play-btn"
								><span class="screen-reader-text"
									>Large toggle button</span
								></i
							>
						</div>
						<!-- /.play-box -->
					</div>
				</div>
				<audio id="audio" preload="none" tabindex="0">
					<source
						v-for="(audio, index) in titleList"
						:key="audio.mhadhara_id"
						:src="audioPath(audio.mhadhara_file)"
						:data-track-number="generateIndex(index)"
					/>
					Your browser does not support HTML5 audio.
				</audio>
			</section>
		</div>
		<vue-loaders
			class="siteLoader"
			name="line-scale"
			color="#19bc9d"
			scale=".9"
			v-if="isLoading"
		></vue-loaders>
		<div class="loadMessage text-danger">{{ loadMessage }}</div>
	</section>
</template>
<script>
import cookie from 'vue-cookies';
import audioPlayer from '../player';
window.addEventListener('load', () => {
	var player = new audioPlayer();
	player.initPlayer();
});
import axios from 'axios';
export default {
	data() {
		return {
			isLoading: false,
			showPlayer: false,
			selectedShekh: '',
			selectedDuruus: '',
			loadShekhLabel: 'loading...',
			loadMessage: '',
			loadScript: 0,
			titleList: [],
			shekhList: [],
			duruusType: [],
		};
	},
	methods: {
		playNow() {
			document.querySelector('.audioError').style = 'display:none;';
			this.showPlayer = true;
		},
		generateIndex(index) {
			return ++index;
		},
		audioPath(filename) {
			let s = cookie.get('confmhs').shekh_id;
			return (
				'https://resources.attamayyuz.or.tz/audio/mihadhara/' +
				s +
				'/' +
				filename
			);
		},
		downloadAudio(file, nm) {
			let s = cookie.get('confmhs').shekh_id;
			window.location.href =
				'https://resources.attamayyuz.or.tz/download.php?t=mh&sh=' +
				s +
				'&file=' +
				file +
				'&name=' +
				nm;
		},
		viewResource() {
			// document.querySelector('.audioError').style="display:none;",
			this.loadMessage = '';
			this.titleList = [];
			cookie.set('confmhs', this.selectedShekh);
			if (this.selectedShekh) {
				// let key = "mh_key";
				// let dataFound = localStorage.getItem(key);
				let date = null;
				this.isLoading = true;
				// if(dataFound!=null){
				// 	var res = JSON.parse(dataFound);
				// 	this.titleList = res.data;
				// 	date = res.max_update;
				// }
				// else{
				// 	this.isLoading = true;
				// }
				axios
					.post(
						'shekhMihadhara',
						{
							shekh_id: this.selectedShekh.shekh_id,
							max_date: date,
						},
						{
							headers: {
								'Content-Type': 'application/json',
							},
						}
					)
					.then((response) => {
						this.isLoading = false;
						let result = response.data;
						if (result.status == 201) {
							this.titleList = result.data;
							// localStorage.removeItem(key)
							// localStorage.setItem(key,JSON.stringify(result));
						} else if (result.status == 1000) {
							// alert("Data Cached")
						} else if (result.status == 200) {
							this.titleList = [];
							this.loadMessage = 'No Data Found';
							// localStorage.removeItem(key);
						}

						this.loadScript = this.loadScript + 1;
					})
					.catch(() => {
						// if(dataFound==null){
						// 	localStorage.removeItem(key)
						this.isLoading = false;
						this.loadMessage = 'SOMETHING WRONG OCCURED !!!';
						// }
					});
			} else {
				this.isLoading = false;
			}
		},

		loadShekh($date) {
			axios
				.put('shekhInMihadhara/' + $date)
				.then((response) => {
					let result = response.data;
					let status = result.status;
					if (status == 201) {
						localStorage.setItem(
							'mihadharaShekhList',
							JSON.stringify(result)
						);
						// alert(JSON.stringify(result))
						this.viewShekh('new');
					} else if (status == 1000) {
						// alert("Data Cached")
					} else {
						this.loadShekhLabel = 'No Shekh Found';
					}
				})
				.catch((err) => {
					if (localStorage.getItem('mihadharaShekhList') == null) {
						console.log(err);
						this.loadShekhLabel = 'AN ERROR OCCURED';
						this.loadMessage =
							'An Error Occured, Please Try Agin Later';
					}
				});
		},
		viewShekh(status) {
			let result = JSON.parse(localStorage.getItem('mihadharaShekhList'));
			this.loadShekhLabel = 'Select Shekh';
			this.shekhList = result.data.map((x) => {
				return { label: x.shekh_name, value: x };
			});

			if (!cookie.isKey('confmhs')) {
				cookie.set('confmhs', '');
			} else {
				// cookie.set('confmhs', '');
				this.selectedShekh = cookie.get('confmhs') ?? '';
				this.loadShekhLabel =
					this.selectedShekh.shekh_name ?? 'SELECT SHEKH';
				// alert(JSON.stringify(this.selectedShekh))
			}
			if (status == 'exist') {
				console.log('hello');
				this.loadShekh(result.max_update);
			}
		},
	},
	watch: {
		selectedShekh() {
			this.viewResource();
		},
		loadScript() {
			setTimeout(function () {
				var player = new audioPlayer();
				player.initPlayer();
			}, 1000);
		},
	},
	created() {
		// this.loadShekh(null);
		if (localStorage.getItem('mihadharaShekhList') != null) {
			this.viewShekh('exist');
		} else {
			this.loadShekh(null);
		}
	},
};
</script>
<style scoped>
.play-list {
	padding: 0px;
	margin: 0px;
}
</style>
