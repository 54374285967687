<template>
	<section>
		<nav
			class="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light"
			id="ftco-navbar"
		>
			<div class="container-fluid">
				<router-link to="/" class="navbar-brand"
					><img src="../../assets/logo1.png"
				/></router-link>
				
				<div class="collapse navbar-collapse" id="ftco-nav">
					<ul class="navbar-nav m-auto">
						<li class="nav-item">
							<w-icon class="icon">mdi mdi-home</w-icon>
							<router-link to="/" class="nav-link"
								>Nyumbani</router-link
							>
						</li>
						<li class="nav-item">
							<w-icon class="icon">mdi mdi-account</w-icon>
							<router-link to="/about" class="nav-link"
								>Kuhusu Sisi</router-link
							>
						</li>
						<li class="nav-item">
							<w-icon class="icon">mdi mdi-phone</w-icon>
							<router-link to="/contact" class="nav-link"
								>Wasiliana Nasi</router-link
							>
						</li>
						<li class="nav-item">
							<w-icon class="icon">fa fa-book</w-icon>
							<router-link to="/books" class="nav-link"
								>Vitabu</router-link
							>
						</li>
						<li class="nav-item">
							
							<w-icon class="icon pt-0">fa fa-volume-up</w-icon>
								<w-menu v-model="showMenu" shadow="shadow" custom="custom">
							<template #activator="{on}">
								
								<a  v-on="on" class="nav-link" :class="{'href-active':$store.state.hrefActive}"
								>
								Sikiliza
								</a>
							</template>
							<w-list class="white--bg" v-model="listSelection" :items="listItems" item-class="px8" @item-select="showMenu = false"></w-list>
							</w-menu>
								
							
							
							<!-- <w-icon class="icon">fa fa-volume-up</w-icon>
							<router-link to="/duruus" class="nav-link"
								>Sikiliza</router-link
							>
							<div class="sub-menu" :class="{'show-sub-menu': true}">
								<button type="button" class="mt-2 mr-2 btn primary--bg btn" v-for="menu in submenu" :key="menu.label" @click="navigate(menu.path)">
									{{menu.label}}
								</button>
			
							</div> -->
						</li>
						
					</ul>
				</div>
			</div>
			
		</nav>
	</section>
</template>
<script>
// import Search from './Search.vue';
export default {

	components: {
		//  'search-form':Search
	},
	data() {
		return {
			showMenu: false,
			listSelection: 'duruus',
			// listItems: [
			// 	{ label: 'Item 1' },
			// 	{ label: 'Item 2' },
			// 	{ label: 'Item 3' }
			// ],
			icons: 'car-wash',
			currentNav: 'home',
			listItems:[
				{label: 'duruus', path:'/duruus'},
				{label: 'khutba', path:'/khutba'},
				{label: 'mihadhara', path:'/mihadhara'},
				{label: 'ruduud', path:'/ruduud'},
				{label: 'quran', path:'/quran'},
			],
		};
	},
	methods: {
		navigate(path){
			if(path!=null){
				this.$router.push(path);
			}
			
		},
		
	},
	watch:{
		listSelection(val){
			if(val!=null)
				this.$router.push("/"+val)
		},
		$route(to){
			let selected = this.listItems.find(e=>e.path==to.path);
			if(selected)
				this.listSelection = selected.label;
			else
				this.listSelection = null
		}
	}
};
</script>
<style scoped>
*{
	box-shadow: none !important;
	margin: 0px;
	padding: 0px;
}
.ftco-navbar-light {
	position: relative;
	background: transparent !important;
	padding: 0;
	padding-top: 6px;
	width: 100%;
	height: 90px;
	border-radius: 0px 0px 0px 0px;
	border-top: 3px solid #19bc9d;
}
.navbar-brand img{
	position: absolute;
	top:-6px;
	left: 0px;
	width: 100%;
	max-width: 100px;
}
.navbar-brand::before{
	content: "";
	position: absolute;
	bottom: 0;
	height: 100%;
	z-index: -1;
	left: -100px;
	width: 210px;
	transform: skew(30deg);
	background: rgba(255, 253, 245, 1);
	border:1px solid #c2ebe3;
	-webkit-box-shadow: 0px 5px 20px -17px brown;
	-moz-box-shadow: 0px 5px 20px -17px brown;
	-o-box-shadow: 0px 5px 20px -17px brown;
	box-shadow: 0px 5px 20px -17px brown;
	border-left: 1px solid #c2ebe3;
	border-bottom: 1px solid #c2ebe3;
}
.ftco-navbar-light::before{
	content: "";
	position: absolute;
	bottom: 0;
	height: 100%;
	z-index: -1;
	left: 120px;
	right: 100px;
	transform: skew(30deg);
	background: #fffdf5;
	-webkit-box-shadow: 0px 5px 20px -17px brown;
	-moz-box-shadow: 0px 5px 20px -17px brown;
	-o-box-shadow: 0px 5px 20px -17px brown;
	box-shadow: 0px 5px 20px -17px brown;
	border-left: 1px solid #c2ebe3;
	border-bottom: 1px solid #c2ebe3;
}
.ftco-navbar-light::after{
	content: "";
	position: absolute;
	bottom: 0;
	height: 100%;
	z-index: -1;
	width: 200px;
	right: 0px;
	background: #fffdf5;
	-webkit-box-shadow: 0px 5px 20px -17px brown;
	-moz-box-shadow: 0px 5px 20px -17px brown;
	-o-box-shadow: 0px 5px 20px -17px brown;
	box-shadow: 0px 5px 20px -17px brown;
	border-bottom: 1px solid #c2ebe3;
}

.ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
	font-size: 10px;
	letter-spacing: 1px;
	padding-top: .5rem;
	padding-bottom: 1rem;
	padding-left: 18px;
	padding-right: 18px;
	font-weight: bold;
	color: dimgray;
	position: relative;
	text-transform: uppercase;
	opacity: .8 !important;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: 0px;
	left: 0;
	background-color: #19bc9d;
	visibility: hidden;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
	z-index: -1;
}

.ftco-navbar-light .navbar-nav > .nav-item > a.router-link-active,
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
	color: #19bc9d;
	cursor: pointer;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover:before {
	visibility: visible;
	background-color: #19bc9d;
	-webkit-transform: scaleX(.5);
	-ms-transform: scaleX(.5);
	transform: scaleX(.5);
}

.ftco-navbar-light .navbar-nav > .nav-item.active > a:before,
.ftco-navbar-light .navbar-nav > .nav-item > a.router-link-active:before,
.ftco-navbar-light .navbar-nav > .nav-item > .href-active:before {
	visibility: visible;
	background-color: #19bc9d;
	-webkit-transform: scaleX(.5);
	-ms-transform: scaleX(.5);
	transform: scaleX(.5);
}

*{
	padding: 0px;
} 


.ftco-navbar-light .navbar-nav > .nav-item .icon{
	color: #19bc9d;
	border-top: 2px solid;
	font-size: 15px;
	height: 35px;
	width: 35px;
}

@media screen and (max-width: 1063px) {
	.ftco-navbar-light .navbar-nav > .nav-item{
		display: none;
	}
}
.sub-menu{
	position: absolute;
	transition: .3s;
	top: 74px;
	left: 0px;
	width: 100%;
	height: 50px;
	/* background: rgba(93, 190, 173,.4); */
	background: rgba(93, 190, 173,.4);
	display: none;
}
.ftco-navbar-light .navbar-nav > .nav-item:hover .sub-menu{
	display: block;
}

.sub-menu button{
	padding: 0px;
	height: 20px;
	width: 100px;
	background: #d05663;
}
</style>
