<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="error-template">
                        <span class="fa fa-warning error-icon"></span>
                        <h1>
                            Oops!
                            </h1>
                        <h2>404 Not Found</h2>
                        <div class="error-details">
                            Sorry, Requested page not found!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.error-template {padding: 40px 15px;text-align: center;}
.error-actions {margin-top:15px;margin-bottom:15px;}
.error-actions .btn { margin-right:10px; }
.error-icon{
    font-size: 40px;
    color: #f95858;
}

</style>