<template>
	<section>
		<div>
			<div class="radio-picture"></div>
			<div>
				<Span>{{ title }}</Span>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	props: ['title'],
	data() {
		return {};
	},
};
</script>
<style scoped>
section {
	padding: 5px;
	height: 50px;
	cursor: pointer;
}
section:hover {
	background: rgba(0, 0, 0, 0.07);
}
section div div {
	float: left;
	height: 40px;
	line-height: 40px;
}
section div div:last-child {
	margin-left: 10px;
}
.radio-picture {
	width: 40px;
	height: 40px;
	border-radius: 40px;
	box-shadow: 0 0 1px #495057 inset;
	background: lightgray;
}
</style>
