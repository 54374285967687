import { createStore } from 'vuex';
const store = createStore({
	state() {
		return {
			user: null,
			isLogin: false,
			token: null,
			activeDot: null,
			hrefActive: false
		};
	},
	mutations: {
		setUser(_, payload) {
			localStorage.setItem('loginUser', payload);
			localStorage.setItem('isLogin', true);
		},
		setListen(state,path){
			state.hrefActive = path=="/duruus" || path=="/khutba" || path=="/ruduud" || path=="/mihadhara" || path=="/khutba" || path=="/quran";
		}
	},
});

export default store;