<template>
	<section>
		<!-- <img src="../assets/book_sample.png" alt=""> -->
		<section class="container mt-3" style="background: rgba(255, 253, 245, .2);">
			<p class="txt-main pa1 text-left text-bold" style="word-spacing: 4px;background: rgba(0,0,0,.01);font-family: quran;font-size: 23px;box-shadow: 0 0 1px lightgray;">VITABU KUHUSU UISLAMU</p>
			<div class="row">
				<div
					class="col-xs-12 mt-2"
					v-for="(book,index) in books"
					:key="index"
				>
					<a
						:href="
							'https://resources.attamayyuz.or.tz/pdf/books/' +
								book.url
						"
						target="_blank"
					>
					<div class="rounded-2 pt-2 pl-3" style="background: rgba(255,255,255,.1);box-shadow: 0 0 2px lightgray inset;">
					
							<p class="text-uppercase primary book-title text-left"><i class="fa fa-file txt-main"></i><span class="ml-3">{{ book.title }}</span></p>

					
				</div>
				</a>
				</div>
			</div>
			<vue-loaders
				class="siteLoader"
				name="line-scale"
				color="#19bc9d"
				scale=".9"
				v-if="isLoading"
			></vue-loaders>
			<p class="error mt-5 mb-5 text-capitalize">{{ msg }}</p>
		</section>
	</section>
</template>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			statusCode: null,
			isLoading: false,
			books: [],
			msg:''
		};
	},
	methods: {
		getBooks() {
			this.isLoading = true;
			axios
				.post('books')
				.then(response => {
					let result=response.data;
					let code = result.status;
					this.isLoading = false;
					if (code == 201) {
						this.books = result.data;
					}
					else{
						this.books = [];
						this.msg="No Data Found"
					}
				})
				.catch(() => {
					this.msg = "FAIL TO GET BOOKS, PLEASE TRY AGAIN LATER";
					this.isLoading = false;
				});
		},
	},
	created() {
		this.getBooks();
	},
};
</script>
<style scoped>
a {
	text-decoration: none;
}
.book_block {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	margin-top: 15px;
	padding: 5px;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
}
.book_block p:first-child {
	color: darkcyan;
}
.book_block p {
	color: rgba(0, 0, 0, 0.7);
	letter-spacing: 1px;
}
.book-title{
	font-family: quran;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
}
</style>
