<template>
	<section>
		<div class="wraper" style="min-height: 100vh;">
			<w-card shadow class="ma2 bg-trans" >
				<div class="row map">
					<iframe
						scrolling="no"
						marginheight="0"
						marginwidth="0"
						src="https://maps.google.com/maps?q=dar%20es%20salaam%20ilaila&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
						width="100%"
						height="300px"
						frameborder="0"
						class="col-lg-6"
					></iframe>
					<div class="col-lg-6 text-left pt5">
						<div class="address">
							<w-icon class="icon">mdi mdi-map</w-icon>
							<span>Ilala / Dar es Salaam</span>
						</div>
						<div class="address">
							<w-icon class="icon">mdi mdi-phone</w-icon>
							<span>+255 679 058 767</span>
						</div>
						<div class="address">
							<w-icon class="icon">mdi mdi-phone</w-icon>
							<span>+255 776 045 121</span>
						</div>
						<div class="address">
							<w-icon class="icon">mdi mdi-email</w-icon>
							<span>info@attamayyuz.or.tz</span>
						</div>
					</div>
				</div>
			</w-card>
			<div class="container-lg" v-if="isLogin">
				<div class="row text-left">
					<div class="col-md-12 mx-auto">
						<div class="contact-form">
							<h3 class="txt-main text-center">
								Please Contact Us
							</h3>
							<!-- <p class="hint-text">
							We'd love to hear from you, please drop us a line if
							you've any query or question.
						</p> -->
							<w-alert success v-if="isSuccess"
								>Request Sent Successfull</w-alert
							>
							<w-alert error v-if="isFail"
								>Fail To Send Request</w-alert
							>
							<form
								action="#"
								method="post"
								@submit.prevent="submit()"
								autocomplete="false"
							>
								<div class="form-group">
									<label for="inputSubject">Subject</label>
									<input
										type="text"
										class="form-control"
										required
										v-model="subject"
									/>
								</div>
								<div class="form-group">
									<label for="inputMessage">Message</label>
									<textarea
										class="form-control"
										rows="5"
										required
										v-model="message"
									></textarea>
								</div>

								<w-button
									type="submit"
									class="btn btn-primary primary--bg"
									:loading="isLoading"
								>
									<w-icon>mdi mdi-check</w-icon>
									Send
								</w-button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<site-footer></site-footer>
	</section>
</template>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			isLogin: false,
			isLoading: false,
			isSuccess: false,
			isFail: false,
			subject: '',
			message: '',
		};
	},
	methods: {
		submit() {
			this.isLoading = true;
			this.isSuccess = false;
			this.isFail = false;
			axios
				.post('contact_us',
					{
						subject: this.subject,
						body: this.message,
					},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization:
								'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then((data) => {
					this.isLoading = false;
					if (data.status == 200) {
						this.subject = '';
						this.message = '';
						this.isSuccess = true;
						this.isFail = false;
					} else {
						this.isSuccess = false;
						this.isFail = true;
					}
				})
				.catch((error) => {
					this.isLoading = false;
					this.isSuccess = false;
					this.isFail = true;

					if(error.response.status == 401){
						this.isLogin = false;
						this.clearStore();
						alert("Please login Again")
					}
					
				});
		},
		isOut() {
			return (
				localStorage.getItem('isLogin') == null ||
				localStorage.getItem('isLogin') != 'true'
			);
		},
		clearStore(){
			this.$store.state.user = null
			this.$store.state.isLogin = false;
			localStorage.removeItem('isLogin');
			localStorage.removeItem('token');
			localStorage.removeItem('loginUser');
		},
	},
	created() {
		this.isLogin = !this.isOut();
	},
};
</script>
<style scoped>
.map {
	margin: 0px;
	padding: 0px;
	padding-top: 20px;
}
.map .address {
	margin-bottom: 40px;
	border-right: 2px solid #19bc9d;
}
.map .address * {
	padding: 3px;
	margin-right: 10px;
}
.map .address .icon {
	padding: 17px;
	background: #19bc9d;
	color: white;
}
.contact-form {
	padding: 0px;
	margin: 50px 0;
}
.contact-form h3 {
	color: #19bc9d;
	font-weight: bold;
	margin: 0 0 15px;
}

.contact-form .btn-primary,
.contact-form .btn-primary:active {
	color: #fff;
	min-width: 100px;
	font-size: 16px;
	background: #19bc9d !important;
	border: none;
}
.contact-form .btn-primary:hover {
	background: #15a487 !important;
}
.contact-form .btn i {
	margin-right: 5px;
}
.contact-form label {
	opacity: 1;
	color: #19bc9d;
	font-weight: bold;
}
.contact-form textarea {
	resize: vertical;
}
.hint-text {
	font-size: 15px;
	padding-bottom: 20px;
	opacity: 0.6;
}
.contact-form h3 {
	padding: 50px;
}
.btn:active,
.form-control,
.form-control:focus,
.input-group-text {
	border-color: #e1e1e1 !important;
}
.form-control,
.btn {
	min-height: 38px;
	border-radius: 2px;
	box-shadow: none;
}
</style>
