<template>
	<div>
		<w-toolbar class="main-toolbar" @mouseleave="showAccountMenu = false">
			<!-- <w-progress color="info" bg-color="info-light3" :fixed="true" :absolute="true" z-index="0"></w-progress> -->
			<div class="hijridate" v-if="!showDrawer">
				{{ arabic }}
			</div>
			<div class="spacer"></div>
			<a
				href="https://www.facebook.com/attamayyuz.attamayyuz.1"
				target="_blank"
				><w-button
					icon="mdi mdi-facebook"
					color="#fcf7e8"
					text
					lg
					class="ml3"
				></w-button
			></a>
			<a
				href="https://twitter.com/attamayyuz"
				target="_blank"
				rel="noopener noreferrer"
			>
				<w-button
					icon="mdi mdi-twitter"
					color="#404d5e"
					text
					lg
					class="ml3"
				></w-button>
			</a>
			<a
				href="https://www.instagram.com/attamayyuz.or.tz/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<w-button
					icon="mdi mdi-instagram"
					color="#404d5e"
					text
					lg
					class="ml3 mr0"
				></w-button>
			</a>
			<span class="account">
				<w-badge
					bg-color="error"
					color="white"
					class="ml3 mr2"
					v-model="showBadge"
					@click="showAccountMenu = !showAccountMenu"
				>
					<template #badge>5</template>
					<w-icon
						bg-color="white"
						color="txt-main"
						size="20px"
						class="account-icon mt-0 pt-0"
						:class="{ 'fa fa-refresh fa-spin': isLogout }"
						>{{ !isLogout ? 'mdi mdi-account' : '' }}</w-icon
					>
				</w-badge>
				<div
					class="account-option"
					:class="{ show_menu: showAccountMenu && !showDrawer }"
				>
					<div class="block">
						<div
							class="action signup"
							@click="account('/register')"
							v-if="$store.state.user == null"
						>
							<i class="fa fa-user"></i> Jisajili
						</div>
						<div
							class="action signin"
							@click="account('/login')"
							v-if="$store.state.user == null"
						>
							<i class="fa fa-sign-in"></i> Ingia
						</div>
						<div
							class="action signin"
							@click="account('/dashboard')"
							v-if="$store.state.user != null"
						>
							<i class="fa fa-dashboard"></i> dashibodi
						</div>
						<div
							class="action logout"
							@click="logout()"
							v-if="$store.state.user != null"
						>
							<i class="fa fa-sign-out"></i> toka nje
						</div>
					</div>
				</div>
			</span>
			<w-button
				class="ml7 mr-1 hamburger-menu white--bg"
				@click="$emit('drawerOpen', !drawerOpen)"
				large="large"
				text="text"
				round="round"
				:icon="drawerOpen ? 'wi-cross' : 'mdi mdi-menu'"
				>just open</w-button
			>
		</w-toolbar>
	</div>
</template>

<script>
import axios from 'axios';
export default {
	props: {
		drawerOpen: { type: Boolean, default: false },
		showDrawer: { type: Boolean, default: false },
	},
	data() {
		return {
			showAccountMenu: false,
			showBadge: false,
			isLogout: false,
			arabic: '',
		};
	},
	methods: {
		account(path) {
			this.$router.push(path);
		},
		logout() {
			this.isLogout = true;
			// alert(this.$route.path)
			// console.log(axios)
			axios
				.post(
					'out',
					{},
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization:
								'Bearer ' + localStorage.getItem('token'),
						},
					}
				)
				.then((response) => {
					this.isLogout = false;
					if (response.status == 200) {
						this.clearStore();
						if (this.$route.path == '/dashboard') {
							this.$router.push('/login');
						}
					}
				})
				.catch((err) => {
					this.isLogout = false;
					if (err.response.status == 401) {
						this.clearStore();
						if (this.$route.path == '/dashboard') {
							this.$router.push('/login');
						}
					}
				});
		},
		clearStore() {
			this.$store.state.user = null;
			this.$store.state.isLogin = false;
			localStorage.removeItem('isLogin');
			localStorage.removeItem('token');
			localStorage.removeItem('loginUser');
		},
		gmod(n, m) {
			return ((n % m) + m) % m;
		},
		kuwaiticalendar(adjust) {
			var today = new Date();
			if (adjust) {
				var adjustmili = 1000 * 60 * 60 * 24 * adjust;
				var todaymili = today.getTime() + adjustmili;
				today = new Date(todaymili);
			}
			var day = today.getDate();
			var month = today.getMonth();
			var year = today.getFullYear();
			var m = month + 1;
			var y = year;
			if (m < 3) {
				y -= 1;
				m += 12;
			}

			var a = Math.floor(y / 100);
			var b = 2 - a + Math.floor(a / 4);
			if (y < 1583) b = 0;
			if (y == 1582) {
				if (m > 10) b = -10;
				if (m == 10) {
					b = 0;
					if (day > 4) b = -10;
				}
			}

			var jd =
				Math.floor(365.25 * (y + 4716)) +
				Math.floor(30.6001 * (m + 1)) +
				day +
				b -
				1524;

			b = 0;
			if (jd > 2299160) {
				a = Math.floor((jd - 1867216.25) / 36524.25);
				b = 1 + a - Math.floor(a / 4);
			}
			var bb = jd + b + 1524;
			var cc = Math.floor((bb - 122.1) / 365.25);
			var dd = Math.floor(365.25 * cc);
			var ee = Math.floor((bb - dd) / 30.6001);
			day = bb - dd - Math.floor(30.6001 * ee);
			month = ee - 1;
			if (ee > 13) {
				cc += 1;
				month = ee - 13;
			}
			year = cc - 4716;
			var wd;
			if (adjust) {
				wd = this.gmod(jd + 1 - adjust, 7) + 1;
			} else {
				wd = this.gmod(jd + 1, 7) + 1;
			}

			var iyear = 10631 / 30;
			var epochastro = 1948084;
			// var epochcivil = 1948085;

			var shift1 = 8.01 / 60;

			var z = jd - epochastro;
			var cyc = Math.floor(z / 10631);
			z = z - 10631 * cyc;
			var j = Math.floor((z - shift1) / iyear);
			var iy = 30 * cyc + j;
			z = z - Math.floor(j * iyear + shift1);
			var im = Math.floor((z + 28.5001) / 29.5);
			if (im == 13) im = 12;
			var id = z - Math.floor(29.5001 * im - 29);

			var myRes = new Array(8);

			myRes[0] = day; //calculated day (CE)
			myRes[1] = month - 1; //calculated month (CE)
			myRes[2] = year; //calculated year (CE)
			myRes[3] = jd - 1; //julian day number
			myRes[4] = wd - 1; //weekday number
			myRes[5] = id - 1; //islamic date
			myRes[6] = im - 1; //islamic month
			myRes[7] = iy; //islamic year

			return myRes;
		},
		writeIslamicDate(adjustment) {
			var wdNames = new Array(
				'Ahad',
				'Ithnin',
				'Thulatha',
				'Arbaa',
				'Khams',
				'Jumuah',
				'Sabt'
			);
			var iMonthNames = new Array(
				'Muharram',
				'Safar',
				"Rabi'ul Awwal",
				"Rabi'ul Akhir",
				'Jumadal Ula',
				'Jumadal Akhira',
				'Rajab',
				"Sha'ban",
				'Ramadan',
				'Shawwal',
				"Dhul Qa'ada",
				'Dhul Hijja'
			);
			var iDate = this.kuwaiticalendar(adjustment);
			var outputIslamicDate =
				wdNames[iDate[4]] +
				', ' +
				(Number(iDate[5]) + Number(1)) +
				' ' +
				iMonthNames[iDate[6]] +
				' ' +
				iDate[7] +
				' AH HAHAH';
			return outputIslamicDate;
		},
	},
	watch: {
		$route() {
			this.showAccountMenu = false;
			this.$store.state.user = localStorage.getItem('loginUser');
		},
	},
	computed: {},
	created() {
		this.arabic = this.writeIslamicDate();
	},
};
</script>

<style scoped>
.account {
	/* cursor: pointer; */
	height: 40px;
	line-height: 50px;
}
.account .account-icon {
	cursor: pointer;
}
.account .account-option {
	position: absolute;
	right: 0px;
	top: 40px;
	width: 190px;
	height: auto;
	padding: 10px;
	z-index: 0;
	display: none;
	padding-top: 2px;
	overflow: hidden;
	word-wrap: break-word;
}
/* .account .account-option::before{
	content: '';
	position: absolute;
	top: 10px;
	right: 25px;
	width: 20px;
	height: 20px;
	background: #19bc9d;
	transform: rotate(45deg);
} */
.account .account-option .block {
	border-radius: 0px;
	background: white;
	padding: 0px 3px;
	box-shadow: 0 0 2px #19bc9d;
}
.account .account-option .fa {
	padding-right: 10px;
}
.account .account-option .action {
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding-left: 4px;
	color: darkcyan;
	text-align: left;
	background: rgba(255, 255, 255, 0.1);
	margin-top: 2px;
}
.account .account-option .action:hover {
	cursor: pointer;
}
.account .account-option.show_menu {
	display: block;
}
div.main-toolbar {
	position: relative;
	background: #19bc9d;
	height: 40px;
	padding-top: 0;
	padding-bottom: 0;
	z-index: 999999;
	top: 0px;
}

div.main-toolbar a {
	color: #fcf7e8;
	text-decoration: none;
}
.hamburger-menu {
	display: none;
}
@media screen and (max-width: 1063px) {
	.hamburger-menu {
		display: block;
	}
}

.hijridate {
	position: absolute;
	left: 0px;
	top: 2px;
	padding: 1px 10px;
	border-radius: 10px;
	color: white;
	font-size: 19px;
	font-family: quran;
	font-weight: bold;
	letter-spacing: 2px;
}
@media screen and (max-width: 1063px) {
	.hijridate {
		top: 70px;
		right: 0px;
		color: #19bc9d;
		border: 1px solid #19bc9d;
		left: auto;
	}
}
</style>
