<template>
	<w-app block>
		<toolbar class="peogress" @drawerOpen="toggleDrawer" :showDrawer="showDrawer"/>
		<nav-bar class="navbar white--bg" :class="{'sticky-top':!showDrawer}" />
		<div  style="position: relative;top:0px;width: 100%;">
			<slot></slot>
		</div>
	</w-app>

	<w-drawer v-model="showDrawer" :left="true" @close="showDrawer = false" width="250px" @update:model-value="$waveui.breakpoint.width">
		<w-button class="button--close" @click="showDrawer = false" sm="sm" outline="outline" round="round" absolute="absolute" color="white" icon="wi-cross"></w-button>
		<sidemenu  @drawerOpen="toggleDrawer" />
	</w-drawer>
</template>
<script>
export default {
	data() {
		return { showDrawer: false, hidecontent: false };
	},
	methods: {
		toggleDrawer(res) {
			this.showDrawer = res;
		},
	},
	watch: {
		showDrawer() {
			if (this.showDrawer) {
				document.querySelector('body').style = 'overflow: hidden;';
				this.hidecontent = false;
			} else {
				document.querySelector('body').style = 'overflow: auto;';
				this.hidecontent = false;
			}
		},
	},
	updated() {
		this.showDrawer = this.showDrawer && this.$waveui.breakpoint.width <= 1063;
	},
	created() {
		document.querySelector('body').style = 'overflow: auto;';
	},
	computed:{
		// position () {
		// 	return this.showDrawer || 'right'
		// }
	}
};
</script>
<style scoped></style>
