<template>
	<section class="container">
		<nav aria-label="breadcrumb" class="mt-3">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link to="/mashairi" class="primary">Mashairi</router-link>
				</li>
				<li
					class="breadcrumb-item active text-bold"
					style="color: #19bc9d;"
					aria-current="page"
				>
					<span v-if="shairiContent">
					{{shairiContent.title}}
					</span>
				</li>
			</ol>
		</nav>

		<div v-if="shairiContent">
			<h6
					class="mt3 text-left white bg-main pa2"
					style="word-spacing: 3px;letter-spacing: 2px;"
				>
				{{shairiContent.title}}
			</h6>
			<div class="content" v-html="shairiContent.content">
			</div>
		</div>
		<vue-loaders
			class="siteLoader"
			name="line-scale"
			color="#19bc9d"
			scale=".9"
			v-if="isLoading"
		></vue-loaders>
	</section>
</template>
<script>
import axios from 'axios';
export default {
	data() {
		return {
			isLoading: false,
			cookieFound: false,
			loadScript: '',
			shairiContent:null
		};
	},
	methods: {
		
	},
	created() {
	
		this.isLoading = true;
		const title_id = this.$route.params.id;
		let obj = {
			shairiDate:null,
			shairiId:title_id
		}
		axios.post('mashairi',obj)
		.then((response) => {
			let result = response.data;
			if (result.status==201) {
				this.shairiContent = result.data
				this.isLoading = false;
			}
		})
		.catch((error) => {
			alert(error)
			this.isLoading = false;
		});
	},
};
</script>
<style scoped>
.breadcrumb{
	position: relative;
	background: transparent;
	-webkit-box-shadow: 0px 5px 10px -17px brown;
	-moz-box-shadow: 0px 5px 10px -17px brown;
	-o-box-shadow: 0px 5px 20px -17px brown;
	box-shadow: 0px 5px 17px -17px brown;
}

.breadcrumb li {
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 1px;
	word-spacing: 3px;
}
.breadcrumb li a {
	text-decoration: none;
}
.content{
	letter-spacing: 1px;
}
</style>
